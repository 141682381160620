import { BasicResponseDTO } from './basicResponse.dto';

export class HostCfgDTO {
  hostcfg_id?: string;
  label: string;
  hw_type: string;
  hw_type_label?: string;
  disabled: boolean;
  target?: boolean;
  ignore_health_status: boolean;
  ignore_health_status_reason?: string;
  limited?: boolean;
  data: Record<string, any>;
}

export class TargetHostCfgDTO {
  hostcfg_id?: string;
  label: string;
  hw_type: string;
  data: Record<string, string>;
  target?: boolean;
}

export class HostCfgResponseDTO extends BasicResponseDTO {
  data: HostCfgDTO;
}

export class HostCfgsResponseDTO extends BasicResponseDTO {
  data: HostCfgDTO[];
}

export interface TypeInfo {
  type: string,
  required: boolean,
  defaultValue?: any,
  possibleValues?: any[],
  arrayItemType?: string,
  arrayItemValues?: any[],
  arrayItemProperties?: Record<string, TypeInfo>,
  properties?: Record<string, TypeInfo>,
  description?: string
}

export type HostcfgDefinition = Record<string, TypeInfo>

export class HostCfgsDefinitionResponseDTO extends BasicResponseDTO {
  data: { definition: HostcfgDefinition | Record<string, HostcfgDefinition>, hw_type: string } | HostcfgDefinition | Record<string, HostcfgDefinition>;
}
