import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { UILog } from "@dto/log.dto";
import { Modal, notification } from "antd";
import axios, { AxiosError, HttpStatusCode } from "axios";
import clc from 'cli-color';
import { ReactNode } from "react";
import { API_URL } from "src/data/Api";
import { DateUtil } from "src/util/DateUtil";
import { TextUtil } from "src/util/TextUtil";
import { postApi } from "src/util/apiCalls";
import { GLOB } from "../util/Glob";
import { RingBuffer } from "../util/ring-buffer";

type ErrorDetail = Error | AxiosError | TypeError | string | object;

export abstract class Log {

  private static readonly STORAGE_KEY = 'uiErrors';

  private static readonly lastWarn: {
    timestamp: number,
    msg
  } = { msg: '', timestamp: 0 };

  private static counter = 0;

  static readonly ERRORS = Log.initErrorBuffer();
  static readonly STATIC_NOTIFICATION_KEY = 'staticNotificationKey';

  static {
    notification.config({ top: 40 })
  }

  private static initErrorBuffer() {
    const rb = new RingBuffer(19);
    const storedJson = sessionStorage.getItem(Log.STORAGE_KEY);
    if (storedJson) {
      rb.fromArray(JSON.parse(storedJson) as unknown[]);
    }
    return rb;
  }

  static success(message: ReactNode) {
    notification.success({
      message: "Success",
      duration: 2,
      description: message,
      className: "success-notification",
      style: {
        color: "darkgreen",
      },
    });
  }

  private static isForbidden(detail: ErrorDetail) {
    return axios.isAxiosError(detail) && detail.response?.status === HttpStatusCode.Forbidden;
  }

  /**
   * Do NOT use directly, sends error to log on server
   * @param log obj to log
   */
  static sendToBE(log: UILog) {
    postApi<BasicResponseDTO, UILog>(API_URL.LOG_ERR, log).catch((reason: AxiosError) => console.error('Failed to store log on server!', reason, log));
  }


  static error(message: ReactNode, detail?: ErrorDetail) {

    if (!GLOB.authService.isLoggedIn() || axios.isCancel(detail)) return;

    let detailString = '';

    if (detail) {
      if (axios.isAxiosError(detail) && detail.response) {
        detailString = JSON.stringify(detail.response, TextUtil.hideKeysReplacer, 2);
      } else if (detail instanceof TypeError) {
        detailString = TextUtil.formatJSON(detail, Object.getOwnPropertyNames(detail));
      } else if (typeof detail === 'object') {
        detailString = JSON.stringify(detail, TextUtil.hideKeysReplacer, 2);
      } else {
        detailString = detail;
      }
    }

    Log.ERRORS.add(clc.red('[Error] ') + DateUtil.getDateISO() + ' ' + clc.red(message) + detailString);
    sessionStorage.setItem(Log.STORAGE_KEY, TextUtil.formatJSON(Log.ERRORS.toArray()));

    const key = Log.counter++ + '';

    notification.error({
      key: key,
      message: (this.isForbidden(detail) ? 'Forbidden' : 'Error') + (detail ? ' (click for detail)' : ''),
      duration: 5,
      description: message,
      className: "error-notification",
      style: {
        //color: "red",
        cursor: detail ? 'pointer' : 'auto'
      },
      //duration: 99999999,
      onClick: () => {
        if (detail) {
          notification.close(key);
          Modal.info({
            title: 'Error detail',
            icon: null,
            centered: true,
            width: '90%',
            closable: true,
            maskClosable: true,
            style: { minWidth: '50%' },
            okButtonProps: { style: { display: 'none' } },
            content: <div style={{ height: '80vh', maxWidth: '90vw' }}>{message}<pre style={{ height: '100%' }}>{detailString}</pre></div>
          });
        }
      },
    });

    //Log.sendToBE({ level: "error", log: detail });
  }

  static info(message: ReactNode, duration = 3) {
    notification.info({
      message: "Info",
      duration,
      description: message,
      className: "info-notification",
    });
  }

  static warn(message: ReactNode, duration = 4) {
    if (Log.lastWarn.timestamp + 1000 > Date.now() && Log.lastWarn.msg == message) return;
    Log.lastWarn.timestamp = Date.now();
    Log.lastWarn.msg = message;
    notification.warning({
      key: duration === 0 ? Log.STATIC_NOTIFICATION_KEY : undefined,
      message: "Warning",
      duration,
      description: message,
      className: "warning-notification",
    });
  }

  /**
   * Relays to {@link console.log} if not production
   * @param params
   * @returns
   */
  static debug(...params: unknown[]) {
    if (GLOB.isProdBuild() && GLOB.isProdEnv()) return;
    console.log(...params);
  }
}

