import { ReloadPart, WebsocketReload } from "@dto/websocketMessage.dto";
import { useEffect, useState } from "react";
import useWebSocketSingleton from "./useWebSocketSingleton";

export const WS_RELOAD_URL = '/websocket/reload';

export const useWebSocketReload = (reloadType: ReloadPart) => {
  const [data, setData] = useState<WebsocketReload>();
  const reload = useWebSocketSingleton<WebsocketReload>(WS_RELOAD_URL);// useWebSocket<WebsocketReload>(WS_RELOAD_URL, reconnect);

  useEffect(() => {
    if (reload && reload.reload == reloadType)
      setData(reload);
  }, [reload, reloadType]);

  return data;
}

export const useWebSocketReloadGetRandomNumberOnReload = (reloadType: ReloadPart) => {
  const [data, setData] = useState<number>();
  const reload = useWebSocketSingleton<WebsocketReload>(WS_RELOAD_URL);

  useEffect(() => {
    if (reload && reload.reload == reloadType)
      setData(Math.random() * 1000);
  }, [reload, reloadType]);

  return data;
}
