import { Item } from '@dto/architecture.dto';
import { ClassKey } from '@dto/constants/class.constants';
import { Empty, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Label } from 'src/data/Label';
import { GLOB } from 'src/util/Glob';
import { ClassType } from '../../data/Class';
import "./Search.less";
import SearchTab from './SearchTab';
import useSearch from './useSearch';

interface Tab {
  label: string;
  class: string;
  items: Item[];
}

function useQuery(): [string, URLSearchParams] {
  const { pathname, search } = useLocation();

  return React.useMemo(() => [pathname, new URLSearchParams(search)], [search]);
}

const Search = () => {
  const [path, query] = useQuery();
  const search = query.has('find') && query.get("find");
  const search_str = search || "";

  const api = `/api/search/v1/item/${encodeURIComponent(search_str)}`;
  const [items, isLoading] = useSearch(path, search_str, api);
  const [addClass, setAddClass] = useState<string[]>([]);

  useEffect(() => {
    document.title = Label.XORMON_NG + ' | Search | ' + search_str;
  }, [search_str]);

  if (isLoading || !items.length) {
    return <div className="xm-search-fail">
      {isLoading ? <Spin tip="Loading..." /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />}
    </div>;
  }

  if (!search) {
    return <div className="xm-search-fail">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
    </div>;
  }

  const tabs: Tab[] = [
    { label: "Server", class: ClassKey.VIRTUALIZATION, items: [] },
    { label: "Storage", class: ClassKey.STORAGE, items: [] },
    { label: "SAN", class: ClassKey.SAN, items: [] },
    { label: "LAN", class: ClassKey.LAN, items: [] },
  ];

  items.forEach(item => {
    for (const tab of tabs) {
      if (tab.class === item.class) {
        tab.items.push(item);
        return;
      }
    }

    const label = item.class.charAt(0).toUpperCase() + item.class.slice(1);
    tabs.push({ label: label, class: item.class, items: [item] });

    if (!addClass.includes(item.class) && !isClass(item.class)) {
      console.warn(`Add Class '${item.class}'`);
      addClass.push(item.class);
      setAddClass(prev => ([...prev, item.class]))
    }
  });

  return (
    <Tabs items={tabs.map(tab => {
      if (!tab.items.length)
        return;

      return {
        key: tab.label,
        label: tab.label,
        className: 'xm-search-tab-wrapper',
        children: <SearchTab key={tab.class} items={tab.items.sort((a, b) => GLOB.naturalSort(a.label, b.label))} />
      }
    })} />
  );
}

function isClass(value: string): value is ClassType {
  return Object.values<string>(ClassKey).includes(value);
}

export default Search;