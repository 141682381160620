import { ArrayItemType, DefaultValue, EnumType, Required, Unrequired } from "@resolve/hostcfg.metadata";
import { PROTO, SnmpAuthProto, SnmpLevel, SnmpPrivProto, SnmpVersion } from "./hostcfgData.types";

export interface IHostcfgDataDescription {
  [key: string]: {
    required: boolean,
    type: "string" | "string[]" | "boolean" | "number" | "number[]" | "format[]",
    description?: string,
    possibleValues?: string[] | number[],
    default?: boolean | string | number | string[]
    format?: IHostcfgDataDescription
  }
}

export interface IBaseDeviceProps {
  vendor: string;
  device: string;
  created: number;
  updated: number | null;
  description: string;
  managementUrl: string;
  disabled: boolean;
  isProxy?: boolean;
  radio?: string;
  sourceAddress?: string;
  licType?: string;
}

export class BaseDeviceProps implements IBaseDeviceProps {
  @Unrequired
  vendor: string = undefined;
  @Required
  device: string = undefined;
  @Unrequired
  created: number = Date.now();
  @Unrequired
  updated: number | null = null;
  @Unrequired
  description: string = undefined;
  @Unrequired
  managementUrl: string = undefined;
  @Unrequired
  disabled: boolean = false;
  @Unrequired
  radio: string = undefined;
  @Unrequired
  sourceAddress?: string = undefined;
  @Unrequired
  licType?: string = undefined;
  @Unrequired
  type?: string = undefined;
}

export const BaseDeviceDescription: IHostcfgDataDescription = {
  device: {
    required: true,
    type: "string",
    description: undefined
  },
  description: {
    required: false,
    type: "string",
    description: "Description of your device configuration"
  },
  managmentUrl: {
    required: false,
    type: "string",
    description: "Your device configuration managment URL"
  }
}

export class PortBaseDeviceProps extends BaseDeviceProps {
  @Required
  host?: string = undefined;
  @Required
  port: number = null;
}

export const PortBaseDeviceDescription: IHostcfgDataDescription = {
  host: {
    required: true,
    type: "string",
    description: undefined
  },
  port: {
    required: true,
    type: "number",
    description: undefined
  },
  ...BaseDeviceDescription
}

export class ProtoPortBaseDevice extends PortBaseDeviceProps {
  @Required
  @EnumType(PROTO)
  @DefaultValue(PROTO.HTTPS)
  protocol: PROTO = PROTO.HTTPS;
}

export const ProtoPortBaseDeviceDescription: IHostcfgDataDescription = {
  protocol: {
    required: true,
    type: "string",
    description: undefined
  },
  ...PortBaseDeviceDescription
}

export class AuthBaseProps extends ProtoPortBaseDevice {
  @Required
  username: string = undefined;
  @Required
  password: string = undefined;
}

export const AuthBaseDescription: IHostcfgDataDescription = {
  ...ProtoPortBaseDeviceDescription,
  username: {
    required: true,
    type: "string",
  },
  password: {
    required: true,
    type: "string",
  }
}

export class VMProps extends AuthBaseProps {
  @Unrequired
  backup_host: string = undefined;
  @Unrequired
  domain: string = undefined;
}

export const VMDescription: IHostcfgDataDescription = {
  ...AuthBaseDescription,
  backup_host: {
    required: false,
    type: "string",
  },
  domain: {
    required: false,
    type: "string",
  }
}

export interface IRestHost {
  apiHost: string;
}

export interface IRestUserPass {
  apiUsername: string;
  apiPassword: string;
}

export interface IRestPort {
  apiPort: number;
}

export interface RestProps extends IRestHost, IRestUserPass, IRestPort { }

export class RestDeviceProps extends BaseDeviceProps implements RestProps {
  @Required
  apiHost: string = undefined;
  @Required
  apiPort: number = null;
  @Required
  apiUsername: string = undefined;
  @Required
  apiPassword: string = undefined;
}

export const RestDeviceDescription: IHostcfgDataDescription = {
  apiHost: {
    required: true,
    type: "string",
  },
  apiPort: {
    required: true,
    type: "number",
  },
  apiUsername: {
    required: true,
    type: "string",
  },
  apiPassword: {
    required: true,
    type: "string",
  },
  ...BaseDeviceDescription
}

export class ProtoRestDeviceProps extends RestDeviceProps {
  @Required
  @DefaultValue(PROTO.HTTPS)
  @EnumType(PROTO)
  proto: PROTO = PROTO.HTTPS;
}

export const ProtoRestDeviceDescription: IHostcfgDataDescription = {
  proto: {
    required: false,
    default: "https",
    type: "string",
    possibleValues: Object.keys(PROTO).map(k => PROTO[k])
  },
  ...RestDeviceDescription
}


export interface ISshUsername {
  sshUsername: string;
}

export interface ISshPassword {
  sshPassword: string;
}

export interface IIsKeyFile {
  isKeyFile: boolean;
}

export interface ISshKeyFile extends IIsKeyFile {
  sshKeyFile: string;
}

export interface ISshPort {
  sshPort: number;
}

export interface ISshProps extends ISshUsername, ISshPort {
  sshHost: string;
}

export class SshDeviceProps extends BaseDeviceProps implements ISshProps {
  @Required
  sshHost: string = undefined;
  @Required
  @DefaultValue(22)
  sshPort: number = 22;
  @Required
  sshUsername: string = undefined;
}

export const SshDeviceDescription: IHostcfgDataDescription = {
  sshHost: {
    required: true,
    type: "string",
    description: undefined
  },
  sshPort: {
    required: true,
    description: undefined,
    type: "number",
    default: 22
  },
  sshUsername: {
    required: true,
    type: "string",
    description: undefined
  },
  ...BaseDeviceDescription
}

export class ExtensiveSshDeviceProps extends SshDeviceProps {
  @Unrequired
  isKeyFile: boolean = false;
  @Required
  sshPassword: string = undefined;
  @Unrequired
  sshKeyFile: string = undefined;
}

export const ExtensiveSshDeviceDescription: IHostcfgDataDescription = {
  sshPassword: {
    required: true,
    type: "string",
    description: undefined
  },
  ...SshDeviceDescription
}

export class SnmpDeviceProps extends BaseDeviceProps {
  @Required
  snmpHost: string = undefined;
  @Required
  snmpPort = 161;
  @Required
  @EnumType(SnmpVersion)
  @DefaultValue(SnmpVersion.SNMPv2c)
  snmpVersion: SnmpVersion = SnmpVersion.SNMPv2c;
  @Required
  @DefaultValue("public")
  snmpCommunity: string = 'public';
  @Required
  snmpSecUser: string;
  @Unrequired
  @EnumType(SnmpLevel)
  @DefaultValue(SnmpLevel.NOAUTH_NOPRIV)
  snmpSecLevel: SnmpLevel = SnmpLevel.NOAUTH_NOPRIV;
  @Unrequired
  @EnumType(SnmpPrivProto)
  @DefaultValue(SnmpPrivProto.AES)
  snmpPrivProtocol: SnmpPrivProto = SnmpPrivProto.AES;
  @Unrequired
  @EnumType(SnmpAuthProto)
  @DefaultValue(SnmpAuthProto.SHA)
  snmpAuthProtocol: SnmpAuthProto = SnmpAuthProto.SHA;

  @Unrequired
  snmpPrivPassword: string;
  @Unrequired
  snmpAuthPassword: string;
}

export const SnmpDeviceDescription: IHostcfgDataDescription = {
  snmpHost: {
    required: true,
    type: "string",
  },
  snmpPort: {
    default: 161,
    type: "number",
    required: true
  },
  snmpVersion: {
    required: true,
    type: "string",
    possibleValues: Object.keys(SnmpVersion).map(key => SnmpVersion[key])
  },
  snmpCommunity: {
    default: "public",
    type: "string",
    required: true
  },
  snmpSecUser: {
    required: true,
    type: "string",
  },
  snmpSecLevel: {
    required: false,
    type: "string",
    possibleValues: Object.keys(SnmpLevel).map(key => SnmpLevel[key]),
    description: "Required if if selected version of snmp is v3"
  },
  snmpPrivProtocol: {
    required: false,
    type: "string",
    possibleValues: Object.keys(SnmpPrivProto).map(key => SnmpPrivProto[key]),
    description: "Required based on your selected security level."

  },
  snmpAuthProtocol: {
    required: false,
    type: "string",
    possibleValues: Object.keys(SnmpAuthProto).map(key => SnmpAuthProto[key]),
    description: "Required based on your selected security level."
  },
  snmpPrivPassword: {
    required: false,
    type: "string",
  },
  snmpAuthPassword: {
    required: false,
    type: "string",
  },
  ...BaseDeviceDescription
}

export class SnmpNetworkDeviceProps extends SnmpDeviceProps {
  @Unrequired
  vfId: number;
  @Unrequired
  fabric_id: string;
  @Unrequired
  fabric_label: string;
}

export const SnmpNetworkDeviceDescription: IHostcfgDataDescription = {
  vfId: {
    required: false,
    type: "string",
    description: "Virtual fabric id"
  },
  ...SnmpDeviceDescription
}

export class SnmpStorageDeviceProps extends SnmpDeviceProps {
  snmpContextName: string;
}

export const SnmpStorageDeviceDescription: IHostcfgDataDescription = {
  ...SnmpDeviceDescription,
  snmpContextName: {
    required: false,
    type: "string",
  }
}

export interface IOldDeviceProps {
  uuid: string;
  proto: string;
  password: string;
  username: string;
  updated: string;
  created: string;
  api_port: string | number;
  auth_ssh: boolean;
  auth_api: boolean;
  host: string;
  hostalias: string;
  devicealias: string;
  disabled: boolean;
  radio: string;
}

export class OldDeviceProps implements IOldDeviceProps {
  uuid: string;
  hostalias: string;
  proto: PROTO;
  password: string;
  username: string;
  updated: string;
  created: string;
  api_port: number;
  auth_ssh: boolean;
  auth_api: boolean;
  host: string;
  devicealias: string;
  "api-port": string;
  disabled: boolean;
  radio: string;
}

export class OldVmProps extends OldDeviceProps {
  domain: string;
  backup_host: string;
}

export class OldRestDeviceProps extends OldDeviceProps {
  "api-user": string;
  "api-port": string;
  "snmp-auth-pass": string;
  "api-password": string;
  "api-password2": string;
  "api-user2": string;
  "snmp-version": string;
  "snmp-port": string;
  "snmp-priv-pass": string;
  "horcm-file": string;
  "snmp-user": string;
  "snmp-host": string;
  "snmp-community": string;
  "host-node": string;
  "api-port-hcm": string;
  device: string;
}

export class OldApiAuthDeviceProps extends OldDeviceProps {
  "api-user": string;
  "api-password": string;
  "api-port": string;
}

export class OldSshDeviceProps extends OldRestDeviceProps {
  "ssh-user": string;
  "ssh-password": string;
  "ssh-key-id": string;
  "ssh-password-enable": number;
  "ssh-port": string | number;
}

export class OldSnmpDeviceProps extends OldDeviceProps {
  "snmp-priv-protocol": string;
  "snmp-auth-pass": string;
  "snmp-version": string;
  "snmp-port": string;
  "snmp-priv-pass": string;
  "snmp-user": string;
  "snmp-host": string;
  "snmp-community": string;
  "fabric": string;
  "snmp-context": string;
  "snmp-sec-level": string;
}

export class UniCliDeviceProps extends BaseDeviceProps {
  @Required
  host: string = undefined;
  @Required
  username: string = undefined;
  @Required
  password: string = undefined;
}

export const UniCliDeviceDescription: IHostcfgDataDescription = {
  host: {
    required: true,
    type: "string",
  },
  username: {
    required: true,
    type: "string",
  },
  password: {
    required: true,
    type: "string",
  },
  ...BaseDeviceDescription
}

export class OldUniCliDeviceProps extends OldDeviceProps {
  "api-user": string;
  "api-password": string;
}

export class TokenRestDeviceProps extends RestDeviceProps {
  @Unrequired
  apiToken: string;
  @Required
  isToken: boolean = false;
}

export class TokenRestDevicePropsOld extends OldRestDeviceProps {
  "api-token": string;
  auth_token: number;
}

export const TokenRestDeviceDescription: IHostcfgDataDescription = {
  apiToken: {
    required: false,
    type: "string",
  },
  isToken: {
    required: true,
    type: "boolean",
  },
  password: {
    required: true,
    type: "string",
  },
  ...RestDeviceDescription
}

export class TwoNodeRestDeviceProps extends RestDeviceProps {
  @Required
  node1Host: string;
  @Unrequired
  node2Host: string;
}

export const TwoNodeRestDeviceDescription: IHostcfgDataDescription = {
  node1Host: {
    required: true,
    type: "string",
  },
  node2Host: {
    required: false,
    type: "string",
  },
  ...RestDeviceDescription
}

export class TwoNodeRestDevicePropsOld extends OldRestDeviceProps {
  host1: string;
  host2: string;
}

export class AbstractDBProps extends PortBaseDeviceProps {
  @Required
  host?: string = undefined;
  @Required
  instance: string = undefined;
  @Required
  username: string = undefined;
  @Required
  password: string = undefined;
  @Unrequired
  use_whitelist: boolean = false;
  @Unrequired
  @ArrayItemType(String)
  dbs: string[] = [undefined];
}


export class AbstractOldDBProps extends OldDeviceProps {
  @Required
  host: string = undefined;
  @Required
  port: number = null;
  @Required
  instance: string = undefined;
  @Required
  username: string = undefined;
  @Required
  password: string = undefined;
  @Unrequired
  use_whitelist: boolean = false;
  @Unrequired
  @ArrayItemType(String)
  dbs: string[] = [''];
}

export const AbstractDBDescription: IHostcfgDataDescription = {
  host: {
    required: true,
    type: "string",
  },
  instance: {
    required: true,
    type: "string",
  },
  username: {
    required: true,
    type: "string",
  },
  password: {
    required: true,
    type: "string",
  },
  use_whitelist: {
    required: false,
    type: "boolean",
    description: "Use database whitelist"
  },
  dbs: {
    required: false,
    type: "string[]",
    description: "List of databases to whitelist"
  },
  port: {
    required: true,
    type: "number",
  }
}