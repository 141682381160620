import { HwTypeKey } from "@dto/constants/hwTypeKey.constants";
import { Card, Checkbox, Col, Form, FormInstance, Input, InputNumber, Modal, Row, Select } from "antd";
import { FormItemProps } from 'antd/lib/form/FormItem';
import { NamePath } from "antd/lib/form/interface";
import { Component, ReactNode } from 'react';
import { HostCfgCardProps } from "src/data/props";
import { nameof } from "ts-simple-nameof";
import { BaseDeviceProps, HostCfgItem, PROTO } from "../ConfigurationTypes";

function path(selector: (props: HostCfgItem) => unknown) {
  return nameof<HostCfgItem>(selector).split('.');
}

export abstract class AbstractDevice<P extends BaseDeviceProps, S = unknown> extends Component<{ cfg: HostCfgItem<P>, form: FormInstance, editing: boolean }, S> {
  protected abstract readonly dataClass: new () => P;
  protected readonly abortCtrl = new AbortController();

  static newDataDialog() {
    Modal.info({
      maskClosable: true,
      title: 'New device data',
      content: <div>
        <p>
          The initial download of configuration and performance data
          from a newly added device can take up to one hour.
        </p>
        <p>Please wait at least 60 minutes and then reload the page.</p>
        <p>You can use the application as usual in the meantime.</p>
      </div>
    });
  }

  static findKey(obj, partKey: string, parents: string[]): { name: string[], value } {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          const element = obj[key];
          if (key.toLowerCase().includes(partKey) && typeof element !== 'object') {
            return { name: [...parents, key], value: element }
          }
          const result = AbstractDevice.findKey(element, partKey, [...parents, key]);
          if (result) return result;
        }
      }
    }
  }

  static removeWhitespace(value: string, namePath: NamePath, form: FormInstance) {
    const trimmed = value.replace(/\s/g, '');
    if (trimmed !== value) {
      form.setFieldValue(namePath, trimmed);
    }
  }

  static getIgnoreHScard(item: HostCfgItem) {
    return <Card {...HostCfgCardProps} >
      <Row>
        <Col span={8}>
          <Form.Item labelCol={{ span: 20 }} wrapperCol={{ span: 4 }} label="Ignore health status" name={path(t => t.ignore_health_status)} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} label="Reason" name={path(t => t.ignore_health_status_reason)}
            rules={[{ required: item?.ignore_health_status, whitespace: true }]}>
            <Input disabled={!item?.ignore_health_status} />
          </Form.Item>
        </Col>
      </Row>
    </Card>;
  }

  componentWillUnmount(): void {
    this.abortCtrl.abort();
  }

  public createItem() {
    const item = new HostCfgItem<P>();
    item.data = new this.dataClass();
    return item;
  }

  protected path(selector: (props: HostCfgItem<P>) => unknown) {
    return nameof<HostCfgItem<P>>(selector).split('.');
  }

  public static protoSelect() {
    return <Select getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}>
      {Object.entries(PROTO).map(([key, val]) => <Select.Option key={key} value={val}>{val}</Select.Option>)}
    </Select>;
  }

  protected getProtoSelect() {
    return AbstractDevice.protoSelect();
  }

  static getPort(label: string, name: NamePath, props?: FormItemProps) {
    return <Form.Item {...props} label={label} name={name}
      rules={[{ required: true, whitespace: true, type: 'number', min: 0, max: 65535 }]}>
      <InputNumber min={0} max={65535} />
    </Form.Item>
  }

  protected getPort(label: string, name: NamePath, props?: FormItemProps) {
    return AbstractDevice.getPort(label, name, props);
  }

  // TODO: hwTypes are no longer array, can be simplified?
  abstract getNewHwType(): HwTypeKey;

  abstract render(): ReactNode;
}

