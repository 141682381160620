import { ClassKey } from "@dto/constants/class.constants";

export const LPAR = [ClassKey.VIRTUALIZATION, ClassKey.DATABASE, ClassKey.CONTAINER, ClassKey.CLOUD] as const;
export const STOR = [ClassKey.STORAGE, ClassKey.SAN, ClassKey.LAN, ClassKey.BACKUP] as const;

export type ClassType = typeof ClassKey[keyof typeof ClassKey];

export function isClassLPAR(cls: string) {
  return (LPAR as readonly string[]).includes(cls)
}

export function isClassSTOR(cls: string) {
  return (STOR as readonly string[]).includes(cls);
}

export function isClassNetwork(cls: string) {
  return cls === ClassKey.SAN || cls === ClassKey.LAN;
}