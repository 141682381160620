import { Badge, BadgeProps } from "antd";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { FC, useMemo } from "react";
import { useAppSelector } from "src/redux/hooks";
import { GLOB } from "src/util/Glob";

export const LicenseBadge: FC<BadgeProps & { hostcfg: boolean; license: boolean; }> = ({ hostcfg, license, ...props }) => {

  const { hostcfgStatus, licenseStatus } = useAppSelector((store) => store.appGlobals);

  const status = useMemo(() => {
    const statuses: PresetStatusColorType[] = [];

    if (hostcfg) //TODO: remove? not used right now
      statuses.push(hostcfgStatus);
    if (license)
      statuses.push(licenseStatus);

    return statuses?.reduce((prev, cur) => prev === 'error' ? prev
      : cur === 'error' ? cur
        : prev === 'warning' ? prev
          : cur, 'default');
  }, [hostcfgStatus, licenseStatus]);

  return GLOB.userInfo.isAdmin ? <Badge status={status} dot={status !== 'default'}
    //title={status === 'error' ? "Your license has expired!" : "Your license will expire soon!"}
    {...props} />
    : <>{props.children}</>;
}