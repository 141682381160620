import { AxiosError } from 'axios';
import React, { createRef, ReactNode, RefObject, Suspense } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.less';
import logo from './assets/logo-xormon.png';
import { Spinner } from './component/spinner/Spinner';
import ErrorBoundary from './content/errorBoundary/ErrorBoundary';
import { ErrorPage, ErrorRoutePage } from './content/errorBoundary/ErrorPage';
import HealthStatusDrawer from './content/globalHealthStatus/HealthStatusDrawer';
import { Login } from './content/login/Login';
import { Logout } from './content/logout/Logout';
import { ChangeLogModal } from './content/releaseInfo/ChangeLog';
import { RoutePath } from './data/Routes';
import { SideNavBar } from './menu/left/SideNavBar';
import TopNavBar from './menu/top/TopNavBar';
import { appGlobConnector, AppGlobProps } from './redux/store';
import { RouteClassTypeItem } from './routing/RouteClassTypeItem';
import { RouteCmp } from './routing/RouteCmp';
import { RouteDevice } from './routing/RouteDevice';
import { RouteGroup } from './routing/RouteGroup';
import { RouteSectionCmp } from './routing/RouteSectionCmp';
import { RouteSettingsHelp } from './routing/RouteSettingsHelp';
import { RouteType } from './routing/RouteType';
import { DataService } from './service/DataService';
import { GLOB } from './util/Glob';

export interface AppState {
  /**
   * @deprecated avoid, TODO: replace
   */
  refresh: boolean;
  isHSOpen: boolean;
  mainScrollRef: RefObject<HTMLDivElement>;
  changeLogModalTimestamp: number;
}

const KEY_LEFT_TREE_WIDTH = 'xm-left-tree-width';

// required for #1250 - keep focus on modal even when element outside modal tree is clicked so close by ESC works
// document.querySelector('body').addEventListener('DOMActivate', ev =>
//   !(ev.target as HTMLElement).closest('.ant-modal,.ant-picker-dropdown,.ant-input') && setTimeout(() => {
//     document.querySelector<HTMLElement>('.ant-modal-wrap')?.focus();
//   }));

class App extends React.Component<AppGlobProps, AppState & { entryElement: ReactNode, leftTreeWidth: number }> {
  constructor(props: AppGlobProps) {
    super(props);
    this.state = {
      refresh: false,
      entryElement: null,
      isHSOpen: false,
      leftTreeWidth: Number(localStorage.getItem(KEY_LEFT_TREE_WIDTH)) || 200,
      mainScrollRef: createRef(),
      changeLogModalTimestamp: 0
    };
    GLOB.getBackendInfo = () => this.props.info;
    GLOB.setBackendInfo = this.props.setInfo;
    GLOB.getEmailGroups = () => this.props.emailGroups;
    GLOB.setEmailGroups = this.props.setEmailGroups;
    GLOB.getState = () => this.state;
    GLOB.setState = this.setState.bind(this);
    void DataService.checkVersionReload();
    GLOB.xormonReady.promise.then(() => {
      this.setState({ entryElement: <Navigate to={GLOB.getDefaultPath()} replace /> });
      this.props.setLicenseStatus(DataService.detectLicenseColor());
    }, (reason: AxiosError) => this.setState({ entryElement: <ErrorPage detail={reason} /> }));
  }

  render() {
    if (!GLOB.authService.isLoggedIn()) {
      return <Login />;
    }

    const showMenu = this.props.showMenu;

    return <Router basename={GLOB.getPublicUrl()}>

      <ReflexContainer orientation="vertical">

        <ReflexElement className='left_movable_panel'
          minSize={showMenu ? 145 : 1}
          size={showMenu ? this.state.leftTreeWidth : 1}
          onStopResize={(args) => {
            this.props.setShowMenu(true);
            const width = (args.domElement as Element).clientWidth;
            this.setState({ leftTreeWidth: width });
            localStorage.setItem(KEY_LEFT_TREE_WIDTH, String(width));
          }}>

          <a className='logo' href='https://xormon.com/' target="_blank" rel="noopener noreferrer">
            <div className='logo-container'>
              <img src={logo} alt='xormon_logo' height={25} />
              {this.props?.info?.backend?.free && (
                <span className='logo-text'>Free Edition</span>
              )}
            </div>
          </a>

          <SideNavBar />
        </ReflexElement>

        <ReflexSplitter />

        <ReflexElement className='d-flex' propagateDimensions={false} propagateDimensionsRate={10}>
          <TopNavBar />
          <HealthStatusDrawer key={`${GLOB.getState().isHSOpen}-state`} />
          <ChangeLogModal timestamp={this.state.changeLogModalTimestamp} />
          <div id="main-scroll" ref={this.state.mainScrollRef}>
            <ErrorBoundary>
              <Routes>
                <Route errorElement={<ErrorRoutePage />}>
                  <Route path="/" element={<Suspense fallback={<Spinner />}>{this.state.entryElement}</Suspense>} />
                  <Route path={RoutePath.RELOAD} element={<Outlet />} />
                  <Route path={RoutePath.LOGOUT} element={<Logout />} />
                  <Route path={RoutePath.MATCH_CMP} element={<RouteCmp />} />
                  <Route path={RoutePath.MATCH_SECTION_CMP} element={<RouteSectionCmp />} />
                  <Route path={RoutePath.MATCH_SETTINGS_HELP} >
                    <Route index element={<RouteSettingsHelp match={RoutePath.MATCH_SETTINGS_HELP} />} />
                    <Route path={RoutePath.MATCH_GROUP_ONLY} >
                      <Route index element={<RouteSettingsHelp match={`${RoutePath.MATCH_SETTINGS_HELP}/${RoutePath.MATCH_GROUP_ONLY}`} />} />
                      <Route path={RoutePath.MATCH_ITEM_ONLY} element={<RouteSettingsHelp match={`${RoutePath.MATCH_SETTINGS_HELP}/${RoutePath.MATCH_GROUP_ONLY}/${RoutePath.MATCH_ITEM_ONLY}`} />} />
                    </Route>
                  </Route>
                  <Route path={RoutePath.MATCH_DEVICE} element={<RouteDevice />} />
                  <Route path={RoutePath.MATCH_TYPE} element={<RouteType />} />
                  <Route path={RoutePath.MATCH_GROUP_TOP} >
                    <Route index element={<RouteGroup />} />
                    <Route path={RoutePath.MATCH_ITEM_ONLY} element={<RouteGroup />} />
                  </Route>
                  <Route path={RoutePath.MATCH_CLASS_TYPE_ITEM} element={<RouteClassTypeItem />} />
                  <Route path={RoutePath.MATCH_SPLAT} element={<Suspense fallback={<Spinner />}>{this.state.entryElement}</Suspense>} />
                </Route>
              </Routes>
            </ErrorBoundary>
          </div>
        </ReflexElement>

      </ReflexContainer>
    </Router>;
  }
}

export default appGlobConnector(App);
