import { CapacityTableColumn } from "@dto/pageResponse.dto";
import { Table } from "antd";
import { ColProps, IGridEntry } from "src/component/grid/Grid";

interface Props<RecordType> {
  data: CapacityTableColumn[];
  cols: ColProps<RecordType & IGridEntry> | ColProps<RecordType & IGridEntry>[];
}

export function TableSummary<RecordType>({ data, cols }: Readonly<Props<RecordType>>) {
  return (
    <Table.Summary.Row>
      {
        data.map((cell, idx) => {
          return (
            <Table.Summary.Cell index={idx} key={idx} {...cols[idx].props}>{cols[idx].props.render(cell.value, data, idx)} </Table.Summary.Cell>
          );
        })
      }
    </Table.Summary.Row>
  );
};