import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { UNIT } from '@dto/constants/subsystemMetricsResponse.constants';
import { Status } from '@dto/healthStatus.dto';
import { CapacityTableColumn, OrientationType, TableColumn } from '@dto/pageResponse.dto';
import { Unit } from '@dto/subsystemMetricsResponse.dto';
import { UnitUtil } from '@dto/util/UnitUtil';
import { TableSummary } from '@parts/table/TableSummary';
import { Button, DatePicker, Input, InputRef, Popover, Space, Table, TableProps, Tooltip, TooltipProps } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { CompareFn, FilterConfirmProps, FilterDropdownProps, SortOrder } from 'antd/lib/table/interface';
import { Property } from 'csstype';
import moment, { Moment } from 'moment';
import { RangeType } from 'rc-picker/lib/RangePicker';
import { DisabledTimes } from 'rc-picker/lib/interface';
import { GetComponentProps } from 'rc-table/lib/interface';
import { getPathValue } from 'rc-table/lib/utils/valueUtil';
import React, { CSSProperties, FC, ReactElement, ReactNode, Ref, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Highlighter from 'react-highlight-words';
import { BsFilter } from 'react-icons/bs';
import { DateUtil } from 'src/util/DateUtil';
import { GLOB } from 'src/util/Glob';
import { TextUtil } from 'src/util/TextUtil';
import { useVT } from 'virtualizedtableforantd4';
import { Log } from '../../service/Log';
import { DivAnimated } from '../divAnimated/DivAnimated';
import './Grid.less';

const filterHelp = (
  <table className="filter-help">
    <tbody>
      <tr>
        <td>
          <code>123</code>
        </td>
        <td>display strings containing 123</td>
      </tr>
      <tr>
        <td>
          <code>&lt;123</code>
        </td>
        <td>display values lower than 123</td>
      </tr>
      <tr>
        <td>&gt;123</td>
        <td>display values greater than 123</td>
      </tr>
      <tr>
        <td>
          <code>=123</code>
        </td>
        <td>display values equal to 123</td>
      </tr>
      <tr>
        <td>
          <code>12-34</code>
        </td>
        <td>display values between 12 and 34</td>
      </tr>
    </tbody>
  </table>
);

class GridState<T> {
  search: Partial<Record<string, { term: React.Key; contains: boolean }>> = {};
  extendedProps: GridProps<T & IGridEntry>;
}

export type FilterType = 'string' | 'number' | 'timestamp';

export class FilterProps {
  field: readonly (string | number)[];
  type?: FilterType = 'string';
  parent?: string;
}

export interface ColProps<RecordType> extends Omit<ColumnProps<RecordType>, 'children'> {
  /**
   * Currently just disables copy to clipboard on click
   */
  plainRender?: boolean;
  filter?: FilterProps;
  minWidth?: Property.MinWidth<string | number>;
  maxWidth?: Property.MaxWidth<string | number>;
  children?: ColProps<RecordType>[] | GridChild<RecordType>[];
  trim?: boolean;
  number?: boolean;
  decimal?: number;
  unit?: Unit;
  magnitude?: number;
  orientation?: OrientationType
  trimLength?: number;
  newLines?: boolean;
}

function formatValue<RecordType>(value: (string | number) | (string | number)[], col: ColProps<RecordType>,) {
  if (Array.isArray(value)) {
    value = value.join(";");
  }
  if (value == null) return value;
  if (!col.number)
    return col.trim ? col.trimLength ? TextUtil.trimMiddle(value, col.trimLength) : TextUtil.trimMiddle(value) : value;

  const num = Number(value);
  const fractions = col.decimal ?? UnitUtil.getUnitFractionDigits(col.unit);
  switch (col.unit) {
    case UNIT.timestamp:
      return DateUtil.getDateISO(num * 1000);
    case UNIT.percent: {
      return value == null ? NaN.toString() : num.toFixed(fractions);
    }
    case UNIT.millisecond:
    case UNIT.cpu_core:
      return num.toFixed(fractions);
    // case UNIT.io_per_second:
    // case UNIT.IOPS:
    // case UNIT.cpu:
    case undefined:
      return num;
    default:
      return (num / col.magnitude).toFixed(fractions);
  }
}

type GridChild<RecordType> = ReactElement<ColProps<RecordType>>;

type GridTableType = "graphTable" | "table";

interface RenderProps {
  noClipboard?: boolean,
  noTooltip?: boolean,
  noTooltipColumns?: string[]
};

export interface GridProps<RecordType> extends TableProps<RecordType> {
  children?: GridChild<RecordType> | GridChild<RecordType>[];
  columns?: ColProps<RecordType>[];
  renderProps?: RenderProps;
  minColumnWidths?: Record<number, number>;
  summaryRow?: CapacityTableColumn[];
}

class IData implements Partial<TableColumn> {
  order?: number;
  value?: (string | number)[];
  bgStatus?: Status;
  renderProps?: { noClipboard?: boolean; noTooltip?: boolean };
}

export class IGridEntry {
  key?: string | number;
  rendered?: Record<string, Record<string, string | number>>;
  //data?: T;
}

function findElementsByClass(node: HTMLElement | null, className: string, foundElements: HTMLElement[]) {
  if (node?.classList?.contains(className)) {
    foundElements.push(node);
  }

  if (node?.childNodes) {
    for (const childNode of node.childNodes) {
      findElementsByClass(childNode as HTMLElement, className, foundElements);
    }
  }
}

function VirtualTable<T extends object>(
  props: Readonly<GridProps<T & IGridEntry>>
) {
  const { mainScrollRef } = GLOB.getState();
  const [scrollHeight, setScrollHeight] = useState(props.scroll?.y || mainScrollRef.current?.clientHeight ? mainScrollRef.current.clientHeight * 0.8 : 800);
  const [vt] = useVT(() => ({ scroll: { y: scrollHeight } }), []);
  const tableRef = useRef<HTMLElement | null>(null);

  function updateMaxWidthOverflow() {
    const header: HTMLElement[] = [];
    const body: HTMLElement[] = [];
    findElementsByClass(tableRef.current, "ant-table-header", header);
    findElementsByClass(tableRef.current, "ant-table-body", body);
    if (header.length) {
      header[0].style.maxWidth = (mainScrollRef.current.clientWidth - 20) + "px";
    }
    if (body.length) {
      body[0].style.maxWidth = (mainScrollRef.current.clientWidth - 20) + "px";
    }
  }

  function updateheight() {
    if (mainScrollRef.current.clientHeight < 200) return;
    const clientHeight = mainScrollRef.current.clientHeight;
    const scale = clientHeight < 1000 ? clientHeight < 600 ? clientHeight < 300 ? 0.5 : 0.6 : 0.7 : 0.8;
    setScrollHeight(clientHeight * scale);
    updateMaxWidthOverflow();
  }

  useEffect(() => {
    window.addEventListener("resize", updateheight);
    updateMaxWidthOverflow();

    return () => { window.removeEventListener("resize", updateheight) };
  }, [])

  return (
    <Table<T>
      {...props}
      pagination={false}
      scroll={props.scroll?.y ? props.scroll : { y: scrollHeight }}
      components={vt}
      bordered={true}
      ref={tableRef as Ref<HTMLDivElement>}
    />
  );
};

let copyNotified = false;

/**
 * Tooltip with default mouse delay
 * @param props
 * @returns
 */
export const TableTooltip: FC<TooltipProps> = props => {
  return <Tooltip mouseLeaveDelay={0.01} mouseEnterDelay={0.5} {...props}>{props.children}</Tooltip>;
}

/**
 * One GridScope for one table
 */
export class GridScope<RecordType extends object> {

  static readonly TH_PADDING_WIDTH = 9;
  static readonly TH_PADDING_SORTER = 11;
  static readonly TH_PADDING_FILTER = 15;
  static readonly ROW_RENDER_LIMIT = 100;
  /**
  * Padding + sorter + filter
  */
  static readonly TH_EXTRA_WIDTH = GridScope.TH_PADDING_WIDTH + GridScope.TH_PADDING_SORTER + GridScope.TH_PADDING_FILTER;


  private tableEl: HTMLDivElement;
  private searchInput: InputRef;
  private data: readonly (RecordType & IGridEntry)[];
  readonly Grid: new (props: GridProps<RecordType & IGridEntry>) => React.Component<GridProps<RecordType & IGridEntry>, GridState<RecordType>>

  constructor(private readonly type: GridTableType = "table") {
    this.Grid = this.createGridClass(type);
  }

  private getState: () => Readonly<GridState<RecordType>>;
  private setState: (
    state:
      | ((
        prevState: Readonly<GridState<RecordType>>,
        props: Readonly<TableProps<RecordType>>
      ) => GridState<RecordType> | null)
      | (GridState<RecordType> | null),
    callback?: () => void
  ) => void;

  private getColumnSearchProps(props: ColProps<RecordType>): ColProps<RecordType> {
    let filterFce: (rowValue, record?: IGridEntry) => boolean;
    let contains = false;

    props.filter.type = props.filter.type ? props.filter.type : 'string';

    const parseNumberFilter = (filter: string) => {
      contains = false;
      if (!filter?.length) {
        return () => true;
      }
      const floatRegex = /[+-]?(\d*[.])?\d+/;
      const rangeRegex = new RegExp('^' + floatRegex.source + '-' + floatRegex.source);
      if (/^\D.+/.test(filter)) {
        const value = filter.substring(1);
        const num = Number(value);
        switch (filter.charAt(0)) {
          case '~':
            contains = true;
            return containsFilter(value);
          case '=':
            return (rowVal: number) => rowVal === num;
          case '<':
            return (rowVal: number) => rowVal < num;
          case '>':
            return (rowVal: number) => rowVal > num;
        }
      } else if (rangeRegex.test(filter)) {
        const filterVals = filter.split('-');
        const left = Number(filterVals[0]);
        const right = Number(filterVals[1]);
        return (rowVal: number) => rowVal >= left && rowVal <= right;
      }
      contains = true;
      return containsFilter(filter);
    };

    const getFieldSliced = () => {
      if (props.filter.field[0] === 'data' || props.filter.field[0] === 'rendered') {
        return props.filter.field.slice(1);
      }
      return props.filter.field;
    }

    const containsFilter = (filter: string) => (rowVal: number, record: IGridEntry) => {
      let value: number = getPathValue(record.rendered, getFieldSliced());
      if (value == null) value = rowVal;
      return value.toString().includes(filter);
    };

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex, type: FilterType) => {
      switch (type) {
        case 'number':
          filterFce = parseNumberFilter(selectedKeys[0]);
          break;
        case 'timestamp':
          filterFce = (rowVal, record) => rowVal >= selectedKeys[0] && rowVal <= selectedKeys[1];
          break;
        case 'string':
          filterFce = (rowVal: string, record: RecordType & IGridEntry) => {
            if (record.rendered) {
              const value: string = getPathValue(record.rendered, getFieldSliced());
              if (value != null)
                return value.toString().toLocaleLowerCase().includes(selectedKeys[0].toLowerCase());
            }
            return rowVal.toString().toLowerCase().includes(selectedKeys[0].toLowerCase());
          }
          break;
        default:
          Log.error('Unknown filter type ' + type);
      }
      const state = { ...this.getState() };
      state.search[props.filter.field.join()] = { term: selectedKeys[0], contains };
      this.setState(state);
      confirm();
    };

    const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
      clearFilters();
      const state = { ...this.getState() };
      state.search[props.filter.field.join()] = null;
      this.setState(state);
      confirm();
    };

    return {
      className: ["xm-search", props.className].join(' ').trim(),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
        let minUnix = Number.MAX_SAFE_INTEGER;
        let maxUnix = 0;
        if (this.data && props.filter.type === 'timestamp') {
          for (const row of this.data) {
            const timestamp: number = getPathValue(row, props.filter.field);
            if (minUnix > timestamp)
              minUnix = timestamp;
            if (maxUnix < timestamp)
              maxUnix = timestamp;
          }
        }
        const minMoment = moment.unix(minUnix);
        const maxMoment = moment.unix(maxUnix);
        function min() {
          return minMoment.clone();
        }
        function max() {
          return maxMoment.clone();
        }
        function disabledDate(current: Moment) {
          const m = current?.clone();
          return !current || m.endOf('day').isBefore(min()) || m.startOf('day').isAfter(max());
        }
        function disabledTime(current: Moment, type: RangeType): DisabledTimes {
          const clone = current?.clone();
          return {
            disabledHours: () => {
              const hours: number[] = [];
              if (clone.endOf('day').isSame(min().endOf('day'))) {
                for (let i = 0; i < min().hour(); i++) {
                  hours.push(i);
                }
              }
              if (clone.endOf('day').isSame(max().endOf('day'))) {
                for (let i = max().hour() + 1; i < 24; i++) {
                  hours.push(i);
                }
              }
              return hours;
            },
            disabledMinutes: hour => {
              const minutes: number[] = [];
              if (clone.endOf('day').isSame(min().endOf('day')) && hour === min().hour()) {
                for (let i = 0; i < min().minute(); i++) {
                  minutes.push(i);
                }
              }
              if (clone.endOf('day').isSame(max().endOf('day')) && hour === max().hour()) {
                for (let i = max().minute() + 1; i < 60; i++) {
                  minutes.push(i);
                }
              }
              return minutes;
            }
          };
        }
        const placeholder = this.data?.length && this.data[0].rendered ? 'displayed data' : 'raw (tooltip) data';
        return (
          <div style={{ padding: 8 }}>
            {props.filter.type === 'timestamp' ?
              <DatePicker.RangePicker className="date-filter-input" disabledDate={disabledDate}
                disabledTime={disabledTime} showTime={{ hideDisabledOptions: true }}
                onChange={(dates, strings) => dates && setSelectedKeys(dates.map(m => m.unix()))} ranges={{
                  //Min: () => [min.clone(), moment.unix(selectedKeys[1] as number)],
                  //Max: () => [moment.unix(selectedKeys[0] as number), max.clone()],
                  MinMax: [min(), max()]
                }}
                defaultValue={[min(), max()]} /> :
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={(props.filter.type === 'string' ? 'Search ' : 'Filter ') + placeholder}
                value={selectedKeys[0] as string}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys as string[], confirm, props.filter.field, props.filter.type)}
                style={{ width: 188, marginBottom: 8 }}
                suffix={
                  props.filter.type === 'number' && (
                    <Popover content={filterHelp}>
                      <InfoCircleOutlined />
                    </Popover>
                  )
                }
              />}
            <br></br>
            <Space>
              <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="primary"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSearch(selectedKeys as string[], confirm, props.filter.field, props.filter.type);
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {props.filter.type === 'string' ? 'Search' : 'Filter'}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => {
        return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
      },
      onFilter: props.onFilter ? props.onFilter : (value: string, record: RecordType & IGridEntry) => {
        const val = getPathValue(record, props.filter.field);
        //if (!val)
        if (val == null) {
          return false;
        }
        return filterFce(val, record);
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput?.select(), 100);
        }
      },
      render: (text, record: RecordType & IGridEntry, index) => {
        const getText = () => {
          const rendered = getPathValue(record.rendered, getFieldSliced());
          return (rendered || text) + '';
        };
        const field = props.filter.field.join();

        return props.render ? (
          props.render(text, record, index)
        ) : this.getState().search[field] && (props.filter.type === 'string' || this.getState().search[field].contains) ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.getState().search[field].term as string]}
            autoEscape
            textToHighlight={getText()}
          />
        ) : (
          text as ReactNode
        );
      },
    };
  }

  private mergeCellStyle(data, oldPropFce: GetComponentProps<unknown>, style: CSSProperties): React.TdHTMLAttributes<unknown> {
    if (oldPropFce) {
      const oldProps = oldPropFce(data);
      return { ...oldProps, style: { ...oldProps.style, ...style } };
    }
    return { style };
  }

  private remapCol(cp: ColProps<RecordType>, minColumnWidths?: Record<number | string, number>) {
    let colProps: ColProps<RecordType> = { filterIcon: <BsFilter />, ...cp };
    if (!colProps.minWidth) {
      colProps.minWidth = TextUtil.measureText(colProps.title as ReactNode, 'min-content',
        this.tableEl?.querySelector('th') || this.tableEl) + GridScope.TH_PADDING_WIDTH
        + (colProps.filter ? GridScope.TH_PADDING_FILTER : 0) + (colProps.sorter ? GridScope.TH_PADDING_SORTER : 0);
    }
    if (colProps.minWidth && cp.orientation !== "vertical") {
      let style = {}
      if (this.data && this.data.length > GridScope.ROW_RENDER_LIMIT && minColumnWidths && colProps.dataIndex) {
        const mWidth = colProps.minWidth.toString().includes("em") ? Number.parseInt(colProps.minWidth.toString()) * 12 : Number.parseInt(colProps.minWidth.toString());
        const minColWidth = minColumnWidths[colProps.dataIndex[1]];
        if (minColWidth) {
          style = { minWidth: mWidth > minColWidth ? colProps.minWidth : minColWidth };
        } else if (minColumnWidths[colProps.dataIndex as string | number]) {
          style = { minWidth: mWidth > minColumnWidths[colProps.dataIndex as string | number] ? colProps.minWidth : minColumnWidths[colProps.dataIndex as string | number] };
        }
        else {
          style = { minWidth: colProps.minWidth };
        }
      }
      else {
        style = { minWidth: colProps.minWidth };
      }
      const oldOnCell = colProps.onCell;
      colProps.onCell = data => this.mergeCellStyle(data, oldOnCell, style);
      const oldOnHeaderCell = colProps.onHeaderCell;
      colProps.onHeaderCell = data => this.mergeCellStyle(data, oldOnHeaderCell, style);
    }
    if (colProps.filter) colProps = { ...colProps, ...this.getColumnSearchProps(colProps) };
    //if (!colProps.sorter && !colProps.onFilter) {
    // nothing
    //} else
    if (colProps.render) {
      if (colProps.plainRender === false || (!colProps.plainRender && !this.getState()?.extendedProps?.renderProps?.noClipboard)) {
        const oldRender = colProps.render;
        colProps.render = (value, record, index) => {
          // if (typeof value === 'object') { // since all values are arrays now
          //   return this.colRend(value, record, index, oldRender, cp);
          // }
          if (typeof value === 'number' || cp.number) return this.colRend(value, record, index, oldRender, cp);
          const jsonText = TextUtil.formatJSON(Array.isArray(value) ? value.join() : value);
          return <CopyToClipboard text={jsonText} onCopy={() => {
            if (copyNotified) return;
            copyNotified = true;
            Log.info(jsonText + ' copied to clipboard');
          }}><DivAnimated>
              {this.colRend(value, record, index, oldRender, cp)}
            </DivAnimated>
          </CopyToClipboard>
        };
      } else {
        const oldRender = colProps.render;
        colProps.render = (value, record, index) => this.colRend(value, record, index, oldRender, cp);
      }
    } else {
      colProps.render = (value, record, index) =>
        this.isTooltip(value, cp.key as string) ? <TableTooltip title={value}>
          <div style={{ textAlign: this.getAlign(value, cp) }}>{value}</div></TableTooltip> : value as React.ReactNode;
    }

    // when sorted, empty value always at the end
    if (colProps.sorter && typeof colProps.sorter !== 'boolean' && colProps.dataIndex) {
      function sorterNullLast(originalSorter: CompareFn<RecordType>, dataIndex?: typeof colProps.dataIndex) {
        return (a: RecordType, b: RecordType, sortOrder: SortOrder) => {
          let aValue: unknown, bValue: unknown;
          if (typeof dataIndex === 'string' || typeof dataIndex === 'number') {
            aValue = a[dataIndex];
            bValue = b[dataIndex];
          } else {
            const getRootValue = (obj: RecordType) => {
              for (const path of dataIndex) {
                if (!obj[path])
                  return '';

                obj = obj[path];
              }

              return obj;
            };

            aValue = getRootValue(a);
            bValue = getRootValue(b);
          }

          if (aValue === '' && bValue === '' || aValue === null && bValue === null)
            return 0;

          if (aValue === '' || aValue === null)
            return sortOrder === 'ascend' ? 1 : -1;

          if (bValue === '' || bValue === null)
            return sortOrder === 'ascend' ? -1 : 1;

          return originalSorter(a, b, sortOrder);
        };
      }

      if (typeof colProps.sorter === 'function')
        colProps.sorter = sorterNullLast(colProps.sorter, colProps.dataIndex);

      if (typeof colProps.sorter === 'object')
        colProps.sorter = { ...colProps.sorter, compare: sorterNullLast(colProps.sorter.compare, colProps.dataIndex) };
    }

    return colProps;
  }

  private colRend(value: any, record: RecordType, index: number, render: ColProps<RecordType>['render'], cp: ColProps<RecordType>) {
    const rendered = render(value, record, index);
    if (rendered?.['type'] === 'meter') {
      return rendered as ReactNode;
    }
    let valTip = value;

    if (typeof value === 'number' || cp.number) valTip = rendered;
    return <div style={{ textAlign: this.getAlign(value, cp) }}>
      {this.isTooltip(value, cp.key as string) ? (
        <TableTooltip title={valTip}><div>{rendered as ReactNode}</div></TableTooltip>
      )
        : rendered as ReactNode
      }
    </div>;
  }

  private isTooltip(value: string | number | (string | number)[], column: string): boolean {
    if (this.getState()?.extendedProps?.renderProps?.noTooltip) return false;
    else if (this.getState()?.extendedProps?.renderProps?.noTooltipColumns?.includes(column)) return false;
    let val = value;
    if (Array.isArray(value))
      if (value.length === 1) val = value[0];
      else val = value.join(' ');
    return (typeof val === 'string' || typeof val === 'number');

  }

  private getAlign(value: string, cp: ColProps<RecordType>): Property.TextAlign {
    switch (cp.filter?.type) {
      case 'timestamp':
        return 'inherit'
      case 'number':
        return 'right';
    }
    if (typeof value === 'number') return 'right';
    return 'inherit';
  }

  private remapChild(cp: GridChild<RecordType>) {
    return { ...cp, props: this.remapCol(cp.props) };
  }

  updateRow(row: RecordType & IGridEntry, data: (RecordType & IGridEntry)[]) {
    return data.map(r =>
      r.key === row.key ?
        row :
        r
    );
  }

  calculateMinWidths(columns: ColProps<RecordType>[], data: (RecordType & IGridEntry)[]) {
    const contentColWidths: Record<number | string, number> = {};
    if (this.type === "graphTable" || !columns || !data) {
      return null;
    }
    const w = this.tableEl?.querySelector('th') || this.tableEl;

    if ((columns.length && columns[0].dataIndex) && !(columns[0].dataIndex instanceof Array)) {
      const keys = [];

      for (const column of columns) {
        keys.push(column.dataIndex);
      }
      let it = 0;
      for (const key of keys as (string | number)[]) {
        if (!contentColWidths[key]) {
          contentColWidths[key] = 0;
        }

        let largestText = "";
        let trimmedText = "";

        for (const row of data) {
          if (!row[key]) continue;
          if (columns[it].newLines) {
            const parts = formatValue(row[key], columns[it]).toString().split("\n");
            for (const part of parts) {
              trimmedText = part
              if (largestText.length < trimmedText.length) {
                largestText = trimmedText;
              }
            }
          } else {
            trimmedText = formatValue(row[key], columns[it]).toString();
            if (largestText.length < trimmedText.length) {
              largestText = trimmedText;
            }
          }

          contentColWidths[key] = TextUtil.measureText(largestText as ReactNode, 'min-content',
            w) + GridScope.TH_PADDING_WIDTH * 4;
        }
        it++;
      }
    } else {
      for (let i = 0; i < columns.length; i++) {
        if (!contentColWidths[i]) {
          contentColWidths[i] = 0;
        }

        let largestText = "";
        let trimmedText = "";

        for (const row of data) {
          const tada = row['data'] as IData[];
          if (!(tada instanceof Array)) {
            return null;
          }
          const datar = tada?.[i]?.value;
          if (!datar) {
            continue;
          }
          if (tada[i]?.bgStatus) {
            continue;
          }
          if (columns[i].newLines) {
            const parts = formatValue(datar, columns[i]).toString().split("\n");
            for (const part of parts) {
              trimmedText = part
              if (largestText.length < trimmedText.length) {
                largestText = trimmedText;
              }
            }
          } else {
            trimmedText = formatValue(datar, columns[i]).toString();
            if (largestText.length < trimmedText.length) {
              largestText = trimmedText;
            }
          }
        }
        contentColWidths[i] = TextUtil.measureText(largestText as ReactNode, 'min-content',
          w) + GridScope.TH_PADDING_WIDTH * 4;
      }
    }
    return contentColWidths;
  }

  /**
   * Syntactic sugar
   * @param _ Column props with filter shorthand
   */
  readonly Col = (_: ColProps<RecordType & IGridEntry>) => {
    return null;
  };

  private createGridClass(type: GridTableType) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const outer = this;
    return class Grid extends React.Component<GridProps<RecordType & IGridEntry>, GridState<RecordType>> {
      constructor(props: GridProps<RecordType & IGridEntry>) {
        super(props);
        outer.getState = () => this.state;
        outer.setState = this.setState.bind(this);
        const gs = new GridState<RecordType>();
        gs.extendedProps = { ...this.props };
        this.state = gs;
      }

      private init(): GridProps<RecordType & IGridEntry> {
        const extProps: GridProps<RecordType & IGridEntry> = { renderProps: { noClipboard: true }, ...this.props };
        outer.data = this.props.dataSource;
        extProps.className = ["xm-grid", extProps.className].join(' ').trim();
        extProps.minColumnWidths = outer.calculateMinWidths(extProps.columns, extProps.dataSource as (RecordType & IGridEntry)[]);
        if (extProps.children) {
          if (!Array.isArray(extProps.children)) extProps.children = [extProps.children];
          extProps.children = extProps.children.filter(c => c).map((child) => {
            if (child.props.children)
              return {
                ...child,
                props: { ...child.props, children: (child.props.children as GridChild<RecordType>[]).map((c) => ({ ...c, props: outer.remapCol(c.props, extProps.minColumnWidths) })) },
              };
            else return outer.remapChild(child);
          });
        } else if (extProps.columns) {
          extProps.columns = extProps.columns.map((child) => {
            if (child.children)
              return {
                ...child,
                children: child.children?.map((c: ColProps<RecordType>) => outer.remapCol(c, extProps.minColumnWidths))
                //props: { ...child, children: child.children?.map((c) => outer.remapCol(c)) },
              };
            else return outer.remapCol(child, extProps.minColumnWidths);
          });
        }
        return extProps;
      }

      componentDidMount(): void {
        this.setState(prev => ({ ...prev, extendedProps: this.init() }))
      }

      componentDidUpdate(prevProps: GridProps<RecordType & IGridEntry>) {

        if (prevProps !== this.props || prevProps.dataSource !== this.props.dataSource || prevProps.columns !== this.props.columns) {
          this.setState({ extendedProps: this.init() });
        }
      }

      render() {
        if (type === "table" && this.props.dataSource && this.props.dataSource.length > GridScope.ROW_RENDER_LIMIT) {
          return <VirtualTable<RecordType> size="small" sticky={true} tableLayout={'auto'} showSorterTooltip={false}
            pagination={false} bordered
            {...this.state.extendedProps} />;
        }
        return <Table<RecordType & IGridEntry> size="small" sticky={true} tableLayout={'auto'} showSorterTooltip={false}
          pagination={false} bordered ref={r => outer.tableEl = r} summary={this.state.extendedProps.summaryRow ? () => <TableSummary<RecordType> data={this.state.extendedProps.summaryRow} cols={this.state.extendedProps.children} /> : null}
          {...this.state.extendedProps} />;
      }
    };
  }

}
