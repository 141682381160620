import { Item, ItemGroupPreview, ItemsResponseDTO } from "@dto/architecture.dto";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { isClassLPAR } from "src/data/Class";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import { ApiPrams, invalidHwProps } from "../Rule";
import { regexApi } from "../comp/preview/Preview";

function useGetAllItemsRegex(cls: string, hw_type: string, subsystem: string, linuxExclude: ItemGroupPreview['linuxExclude'], additionalApiParm: ApiPrams = {}): [Item[], boolean, Promise<AxiosResponse<ItemsResponseDTO, ItemGroupPreview>>] {
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem });
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [promise, setPromise] = useState<Promise<AxiosResponse<ItemsResponseDTO, ItemGroupPreview>>>();

  useEffect(() => {
    if (invalidBaseProps)
      return;

    const processData = ({ data: { data } }: AxiosResponse<ItemsResponseDTO, ItemGroupPreview>) => {
      setItems(data.sort((a, b) => GLOB.naturalSort(a.label, b.label)));
    };

    setIsLoading(true);
    const controller = new AbortController();
    const postPromise = regexApi(
      {
        class: cls,
        subsystem,
        hw_type: isClassLPAR(cls) ? hw_type : undefined,
        regex: [".*"],
        linuxExclude
      },
      { signal: controller.signal, params: additionalApiParm },
    );

    postPromise.then(
      processData,
      (reason: AxiosError) => Log.error('Failed to retrieve items!', reason)
    ).finally(() => setIsLoading(false));

    setPromise(postPromise);

    return () => controller.abort();
  }, [hw_type, subsystem]);

  return [items, isLoading, promise];
}

export default useGetAllItemsRegex;