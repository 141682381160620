import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlayCircleOutlined, WarningOutlined
} from '@ant-design/icons'
import { IHwTypeBase } from '@dto/architecture.dto'
import { HwTypeKey } from '@dto/constants/hwTypeKey.constants'
import { BaseDeviceProps } from '@dto/hostcfgs/deviceProps'
import { Button, Card, Checkbox, Form, FormInstance, Input, Modal, Popconfirm } from 'antd'
import { FormItemProps, Rule, RuleObject } from 'antd/lib/form'
import { NamePath, StoreValue } from 'antd/lib/form/interface'
import { debounce } from 'lodash'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Component, ReactNode, createRef } from 'react'
import { Help } from 'src/component/help/Help'
import { Label } from 'src/data/Label'
import { TextUtil } from 'src/util/TextUtil'
import { nameof } from 'ts-simple-nameof'
import { ColProps, TableTooltip } from '../../../../component/grid/Grid'
import { BasicFormProps, FormValidateMessages, HostCfgCardProps } from '../../../../data/props'
import { Log } from '../../../../service/Log'
import { GLOB } from '../../../../util/Glob'
import { Deferred } from '../../../../util/deferred'
import {
  createHostCfg,
  deleteHostCfg,
  fetchData,
  getHostCfgList,
  updateHostCfg
} from '../ConfigurationService'
import { HostCfgItem } from '../ConfigurationTypes'
import { ConnectionTestModal } from '../ConnectionTest'
import '../HostCfg.less'
import TableConfiguration from '../TableConfiguration'
import { AbstractDevice } from '../stor/AbstractDevice'

export interface PlatformState<P extends BaseDeviceProps, S> {
  data: HostCfgItem<P>[];
  dialog: Partial<S>;
  cfgInEdit: HostCfgItem<P>;
  modalVisible: boolean;
  actionName: string;
  timestamp: number;
  saving: boolean;
  canAddDevice: boolean;
  proxyColumn: boolean;
}

export abstract class AbstractPlatform<P extends BaseDeviceProps, S = Record<string, unknown>, T extends PlatformState<P, S> = PlatformState<P, S>>
  extends Component<unknown, T> {
  private readonly columns: ColProps<HostCfgItem<P>>[] = ([
    {
      title: 'Name',
      key: 'hostalias',
      filter: { field: ['label'] },
      sorter: (a, b) => GLOB.naturalSort(a.label, b.label),
      render: (value, record) => (!record.target
        ? <a
          className='d-flex hover-info'
          title={record.data.description}
          onClick={() => this.showModalForm(record, 'Edit')}
        >
          {record.label}
        </a>
        : <span className='d-flex' title={record.data.description}>{record.label}</span>
      ),
    },
    {
      title: 'Hostname',
      key: 'host',
      width: '20%',
      render: (value, record) => {
        const hosts = this.parseHosts(record.data)
        return hosts.join(', ')
      },
    },
    {
      title: 'Proxy',
      key: 'proxy',
      width: '4em',
      render: (value, record) =>
        (record.target === true ? record.data.sourceAddress : "")
    },
    {
      title: 'Test',
      key: 'test',
      width: '4em',
      render: (value, record) => (
        <Button
          type='text'
          className='hover-info text-center'
          icon={<PlayCircleOutlined />}
          title='Test connection'
          onClick={() => this.testCon(record)}
          disabled={record.target || GLOB.getBackendInfo()?.backend?.demo}
        />
      ),
    },
    !GLOB.isProdEnv() && {
      title: 'Fetch',
      key: 'fetch',
      width: '4em',
      render: (value, record) => (
        <Button
          type='text'
          className='hover-info text-center'
          icon={<PlayCircleOutlined />}
          title="Fetch data"
          onClick={() => fetchData(record)}
          disabled={record.target}
        />
      )
    },
    {
      title: 'Mgmt',
      key: 'management',
      width: '4em',
      render: (value, record) =>
        record.data.managementUrl ? (
          <Button
            type='text'
            className='hover-info text-center'
            icon={<ExportOutlined />}
            title='Management'
            onClick={() => window.open(record.data.managementUrl, '_blank')}
            disabled={record.target}
          />
        ) : null,
    },
    {
      title: 'Edit',
      key: 'edit',
      width: '3em',
      render: (value, record) => (
        <Button
          type='text'
          className='hover-info text-center'
          icon={<EditOutlined />}
          title='Edit configuration'
          onClick={() => this.showModalForm(record, 'Edit')}
          disabled={record.target}
        />
      ),
    },
    {
      title: 'Clone',
      key: 'clone',
      width: '4em',
      render: (value, record) => (
        <Button
          type='text'
          className='hover-info text-center'
          icon={<CopyOutlined />}
          title='Clone configuration'
          onClick={() =>
            this.showModalForm({ ...record, hostcfg_id: null, source: record.hostcfg_id }, 'Clone')
          }
          disabled={record.target}
        />
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      width: '4em',
      render: (value, record) =>
        <Popconfirm
          okText='Delete'
          title={
            <span style={{ marginLeft: '0.5em' }}>
              Delete <strong>{record.label}</strong> configuration?
            </span>
          }
          onConfirm={() => this.deleteCfg(record)}
          icon={<WarningOutlined style={{ fontSize: '1.5em', top: '0.25em' }} />}
        >
          <Button
            type='text'
            className='hover-danger text-center'
            icon={<DeleteOutlined />}
            title='Delete configuration'
          />
        </Popconfirm>
      ,
    },
    {
      key: 'description',
      title: 'Description',
      render: (value, record) => {
        return <TableTooltip title={record.data.description}>{TextUtil.trimEnd(record.data.description, 64)}</TableTooltip>
      }
    },
  ] as ColProps<HostCfgItem<P>>[]).filter(c => c);

  protected readonly abortCtrl = new AbortController();
  private readonly deferredSave = new Deferred();
  private readonly deferredMounted = new Deferred();
  private readonly cls: new () => P;
  private readonly debouncedChangeState = debounce((changedValues: Partial<HostCfgItem>, values) => {
    this.setState({ cfgInEdit: this.formRef.current.getFieldsValue(true) });
    const host = AbstractDevice.findKey(changedValues, 'host', []);
    if (host) {
      AbstractDevice.removeWhitespace(host.value, host.name, this.formRef.current);
    }
  }, GLOB.TIMEOUT_DEBOUNCE_SHORT);

  protected abstract installUrl: string;
  protected readonly hwType: HwTypeKey
  protected hwTypeFull: IHwTypeBase
  protected readonly formRef = createRef<FormInstance<HostCfgItem<P>>>()
  protected readonly uniqueNameRule: Rule = {
    validateTrigger: ['onChange'],
    validator: GLOB.debounceAsync(
      (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: string) => void
      ): Promise<void | string> => {
        if (this.isEdit() && this.state.cfgInEdit.label === value) return Promise.resolve()
        if (this.getFilteredData().some((cfg) => cfg.label === value)) {
          const msg = `Name ${value} already used!`
          Log.warn(msg)
          return Promise.reject(new Error(msg))
        }
        return Promise.resolve()
      },
      GLOB.TIMEOUT_DEBOUNCE_LONG
    ),
  }

  protected cannotAddReason: string = Label.LICENSE_LIMIT;
  readonly state: T;
  constructor(props, hwType: HwTypeKey, cls: new () => P) {
    super(props)
    this.saveCfg = this.saveCfg.bind(this)
    this.onFormFinishFailed = this.onFormFinishFailed.bind(this)
    this.state = {
      data: [],
      dialog: {},
      cfgInEdit: null,
      modalVisible: false,
      actionName: '',
      timestamp: null,
      saving: false,
      canAddDevice: false,
      proxyColumn: false
    } as T;
    this.hwType = hwType
    this.cls = cls
    void GLOB.menuService.hwTypesLoaded.promise.then((types) => {
      this.hwTypeFull = types.find((ht) => ht.hw_type == this.hwType)
      return types
    })
    this.init()
  }

  protected getFilteredData() {
    return this.state.data;
  }

  componentWillUnmount(): void {
    this.debouncedChangeState.cancel();
    this.deferredMounted.reject();
    this.abortCtrl.abort();
  }

  componentDidMount(): void {
    this.deferredMounted.resolve();
  }

  componentDidUpdate(prevProps: Readonly<unknown>, prevState: Readonly<PlatformState<P, S>>, snapshot?: any): void {
    if (prevState.data.length !== this.state.data.length) {
      this.setState(prev => ({ ...prev, proxyColumn: this.hasProxyColumn() }));
    }
  }

  private init() {
    void getHostCfgList(null, this.hwType).then((response) => {
      void this.deferredMounted.promise.then(() => {
        const data = response.data.data.map((cfg) => ({
          ...cfg,
          key: cfg.hostcfg_id,
        })) as HostCfgItem<P>[];
        this.setState({ data }, () => this.setState({ canAddDevice: this.canAddDevice(), proxyColumn: this.hasProxyColumn() }))
      })
    })
  }

  private parseHosts(hostProperty): string[] {
    if (typeof hostProperty === 'string') return [hostProperty]
    else if (Array.isArray(hostProperty)) {
      return hostProperty.flatMap((child) => this.parseHosts(child))
    } else if (typeof hostProperty === 'object') {
      const hosts: string[] = []
      for (const key in hostProperty) {
        if (
          Object.hasOwn(hostProperty, key) &&
          key.toLowerCase().includes('host')
        ) {
          const element = hostProperty[key]
          hosts.push(...this.parseHosts(element))
        }
      }
      return hosts
    }
    return []
  }

  protected canAddDevice() {
    const hwType = GLOB.menuService.hwTypes.find(ht => ht.hw_type === this.hwType);
    const clsLimit = GLOB.getBackendInfo().backend.limits[hwType.class];
    if (!clsLimit) return true;
    const limit: number = clsLimit.limit || clsLimit.hwTypes[hwType.hw_type]?.limit;
    if (limit == null || limit < 0) return true;
    return limit > this.state.data.filter(hci => !hci.disabled && !hci.limited).length;
  }

  protected getSubTypeLimit(type?: string) {
    const hwType = GLOB.menuService.hwTypes.find(ht => ht.hw_type === this.hwType);
    const clsLimit = GLOB.getBackendInfo().backend.limits[hwType.class];
    if (!clsLimit) return;
    const typeLimits = clsLimit.hwTypes[hwType.hw_type]?.types;
    if (!typeLimits) return;
    for (const key in typeLimits) {
      if (Object.hasOwn(typeLimits, key)) {
        const limit = typeLimits[key];
        if ((!type || type === key) && limit >= 0 && this.state.data.filter(cfg => cfg.data.licType === key).length > limit)
          return { limit, hwType, key };
      }
    }
  }

  private hasProxyColumn() {
    return this.getFilteredData().filter(item => item.target).length > 0;
  }

  protected abstract getFormItems(cfg: HostCfgItem<P>): ReactNode

  protected testCon(cfg: HostCfgItem<P>) {
    this.setState({ cfgInEdit: cfg, timestamp: Date.now() })
  }

  protected async deleteCfg(cfg: HostCfgItem<P>) {
    const promise = deleteHostCfg(cfg)
    void promise.then((resp) => {
      this.setState(prev => ({ data: prev.data.filter((hci) => hci.hostcfg_id !== cfg.hostcfg_id) }));
      this.init();
      Log.info('Successfully deleted configuration ' + cfg.label);
    })
    return promise;
  }

  protected getCfgToSave() {
    return this.formRef.current.getFieldsValue(true) as HostCfgItem<P>
  }

  protected saveCfg(part: HostCfgItem) {
    const cfg = this.getCfgToSave()
    if (cfg.hostcfg_id) {
      cfg.data.updated = Date.now()
      delete cfg.key
      updateHostCfg(cfg).then(
        (response) => {
          this.init()
          this.deferredSave.resolve()
          Log.info(`Configuration ${cfg.label} updated.`)
        },
        (reason) => this.deferredSave.reject()
      )
    } else {
      cfg.hw_type = this.hwType
      cfg.data.created = Date.now()
      cfg.data.updated = null
      createHostCfg(cfg).then(
        (response) => {
          this.init()
          const created = response.data.data
          this.deferredSave.resolve()
          Log.info(`Configuration ${created.label} created.`)
          AbstractDevice.newDataDialog()
        },
        (reason) => this.deferredSave.reject()
      )
    }
  }

  protected onFormFinishFailed(error: ValidateErrorEntity) {
    Log.error('Form contains error!', error)
    this.deferredSave.reject()
  }

  protected prepareCfgForModal(cfg: HostCfgItem<P>) {
    return cfg
  }

  protected showModalForm(cfg: HostCfgItem<P>, actionName: string) {
    this.setState({
      cfgInEdit: this.prepareCfgForModal(cfg),
      modalVisible: true,
      actionName,
    })
  }

  protected path(selector: (props: HostCfgItem<P>) => unknown) {
    return nameof<HostCfgItem<P>>(selector).split('.')
  }

  protected isEdit() {
    return !!this.state.cfgInEdit?.hostcfg_id
  }

  protected getPort(label: string, name: NamePath, props?: FormItemProps) {
    return AbstractDevice.getPort(label, name, props)
  }

  protected protoSelect() {
    return AbstractDevice.protoSelect()
  }

  protected commonCard(aliasLabel = 'Host alias') {
    return (
      <Card
        {...HostCfgCardProps}
        title='Common options'
        extra={
          <Form.Item
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 6 }}
            label='Disabled'
            name={this.path((t) => t.disabled)}
            valuePropName='checked'
            style={{ marginLeft: '1em', marginBottom: 0 }}
          >
            <Checkbox />
          </Form.Item>
        }
      >
        <Form.Item
          label={aliasLabel}
          name={this.path((t) => t.label)}
          rules={[{ required: true, message: 'Please input host alias!' }, this.uniqueNameRule, GLOB.TitleCharactersValidatorRule]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Description' name={this.path((t) => t.data.description)}>
          <Input />
        </Form.Item>

        <Form.Item label='Management URL' name={this.path((t) => t.data.managementUrl)} rules={[{ pattern: GLOB.URL_PATTERN, message: "Management URL is not valid" }]}>
          <Input type='url' />
        </Form.Item>
      </Card>
    )
  }

  protected getNewDialogState(): S {
    return {} as S;
  }

  protected getNewData() {
    return new this.cls();
  }

  render(): ReactNode {
    return (
      <div className='xm-host-cfg xm-host-cfg-lpar'>
        <Button
          disabled={!this.state.canAddDevice}
          title={this.state.canAddDevice ? null : this.cannotAddReason}
          onClick={(e) => {
            this.setState({ dialog: this.getNewDialogState() });
            const cfg = new HostCfgItem<P>();
            cfg.data = this.getNewData();
            this.showModalForm(cfg, 'New');
          }}
        >
          Add new device
        </Button>
        <TableConfiguration columns={(this.state.proxyColumn ? this.columns : this.columns.filter(value => value.key !== "proxy"))}
          dataSource={this.getFilteredData()} subTypeLimit={this.getSubTypeLimit()} />
        <Modal
          key="xmHostCfgModal"
          className='xm-hostcfg-modal'
          maskClosable={false}
          closable={false}
          open={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ saving: true });
            this.deferredSave.reset()
            void this.deferredSave.promise.then(() => {
              this.setState({ modalVisible: false });
              this.init();
            }).finally(() => this.setState({ saving: false }));
            this.formRef.current.submit();
          }}
          okButtonProps={{ loading: this.state.saving }}
          okText='Save'
          destroyOnClose={true}
          title={<div>
            {this.state.actionName} {this.hwTypeFull?.label} configuration
            <Help title='Installation instructions' style={{ float: 'right' }} href={this.installUrl} />
          </div>}
        >
          <Form
            ref={this.formRef}
            className='xm-form xm-form-space'
            style={{ minHeight: '20em' }}
            initialValues={this.state.cfgInEdit}
            onFinish={(values) => this.saveCfg(values)}
            onFinishFailed={(errorInfo) => this.onFormFinishFailed(errorInfo)}
            onValuesChange={this.debouncedChangeState}
            validateMessages={FormValidateMessages}
            {...BasicFormProps}
          >
            {this.getFormItems(this.state.cfgInEdit)}

            {AbstractDevice.getIgnoreHScard(this.state.cfgInEdit)}
          </Form>
        </Modal>
        <ConnectionTestModal cfg={this.state.cfgInEdit} timestamp={this.state.timestamp} />
      </div>
    )
  }
}
