export enum HwTypeKey {
    // Virtualization
    LINUX = 'linux',
    VMWARE = 'vmware',
    PROXMOX = 'proxmox',
    NUTANIX = 'nutanix',
    POWER = 'power',
    OVIRT = 'ovirt',
    XENSERVER = 'xenserver',
    WINDOWS = 'windows',
    SOLARIS = 'solaris',
    ORACLEVM = 'oraclevm',
    CLOUDSTACK = 'cloudstack',
    FUSIONCOMPUTE = 'fusioncompute',
    AS400 = 'as400',

    // NOT SUPPORTED VIRTUALIZATION
    HUAWEI = 'fusioncompute',
    XEN = 'xenserver',

    // Database
    POSTGRES = 'postgres',
    SQLSERVER = 'sqlserver',
    ORACLEDB = 'oracledb',
    TIMESCALEDB = 'timescaledb',
    DB2 = 'db2',

    // Storage
    _3PAR = '3par',
    COMPELLENT = 'compellent',
    DATADOMAIN = 'datadomain',
    DOTHILL = 'dothill',
    ESERIES = 'eseries',
    IBMCOSS = 'ibmcoss',
    ISILON = 'isilon',
    POWERFLEX = 'powerflex',
    RUBRIK = 'rubrik',
    STOREONCE = 'storeonce',
    SWIZ = 'swiz',
    QNAP = 'qnap',
    ORACLEZFS = 'oraclezfs',
    COHESITY = 'cohesity',
    VMAX = 'vmax',
    GPFS = 'gpfs',
    NETBACKUP = 'netbackup',
    MACROSAN = 'macrosan',
    CEPH = 'ceph',
    DATACORE = 'datacore',
    DS5K = 'ds5k',
    DS8K = 'ds8k',
    ECS = 'ecs',
    ETERNUS = 'eternus',
    FALCONSTOR = 'falconstor',
    FREENAS = 'freenas',
    HCP = 'hcp',
    HNAS = 'hnas',
    HUS = 'hus',
    INFINIBOX = 'infinibox',
    INFORTREND = 'infortrend',
    NETAPP = 'netapp',
    NIMBLE = 'nimble',
    OCEANSTOR = 'oceanstor',
    PURE = 'pure',
    PUREBLADE = 'pureblade',
    QUMULO = 'qumulo',
    RAIDIX = 'raidix',
    SOLIDFIRE = 'solidfire',
    STOREVIRTUAL = 'storevirtual',
    SYNOLOGY = 'synology',
    VNX = 'vnx',
    VNXE = 'vnxe',
    VNXF = 'vnxf',
    VPLEX = 'vplex',
    VSPG = 'vspg',
    UNITY = 'unity',
    XIV = 'xiv',
    XTREMIO = 'xtremio',
    STORAGEGRID = 'storagegrid',
    POWERSTORE = 'powerstore',
    DDNINTELLIFLASH = 'ddnintelliflash',
    OCEANSTORPACIFIC = 'oceanstorpacific',
    OCEANSTORPACIFICNAS = 'oceanstorpacificnas',
    OCEANSTORNAS = 'oceanstornas',

    // San
    SANBRCD = 'sanbrcd',
    SANCISCO = 'sancisco',
    SANNAV = 'sannav',

    // LAN
    LANOTHERS = 'lanothers',
    LANCISCO = 'lancisco',

    // Container
    KUBERNETES = 'kubernetes',
    OPENSHIFT = 'openshift',
    DOCKER = 'docker',

    // Cloud
    AZURE = 'azure',
    AWS = 'aws',
    GCLOUD = 'gcloud',
    IBMCLOUD = "ibmcloud",

    //Backup
    IBMTSM = 'ibmtsm',

    //hwTypes added for vendors.constants

    INFINIGUARD = "infiniguard",
    VTRAK = "vtrak",
    EQUALOGIC = "equalogic"
}
