import { COLORS, STATUS } from '@const/healthStatus.constants';
import { RELOAD_PART } from '@dto/constants/websocketMessage.constants';
import { Status } from '@dto/healthStatus.dto';
import { Tabs } from "antd";
import { useWebSocketReload } from 'src/hook/useWebSocketReload';
import CounterCircle from './CounterCircle';
import StateTab from './StateTable';
import useHealthCount from './hook/useHealthCount';

export interface Tab {
  label: JSX.Element | string;
  key: Status;
  children: JSX.Element;
}

const StateTabs = () => {
  const data = useWebSocketReload(RELOAD_PART.healthStatus);
  const state = useHealthCount([data]);
  const makeLabel = (key: string) => (key.charAt(0) + key.slice(1).toLowerCase());
  const tabs: Tab[] = [];
  const { UNDEFINED: _, ...omitUndefined } = STATUS;
  let key: keyof typeof omitUndefined;
  for (key in omitUndefined) {
    tabs.push({
      label: key !== 'OK' && state[STATUS[key]] > 0 ?
        <CounterCircle counter={state[STATUS[key]]} xShift={97} yShift={-20} color={COLORS[STATUS[key]]}>
          {makeLabel(key)}
        </CounterCircle> : key === 'OK' ? key : makeLabel(key),
      key: STATUS[key],
      children: <StateTab wsData={data} key={`${STATUS[key]}-tab`} type={STATUS[key]} url={`/api/global_status/v1/health/severity/${STATUS[key]}`} />,
    });
  }

  return <Tabs items={tabs} />;
};

export default StateTabs;
