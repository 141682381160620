import { HostCfgDTO } from "@dto/hostCfgResponse.dto";
import { FormInstance, ModalFuncProps } from "antd";
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { STOR } from '../../../data/Class';
import { AppDispatch } from "../../../redux/store";
import { Deferred } from "../../../util/deferred";

export class HostCfgItem<DeviceProps extends BaseDeviceProps = BaseDeviceProps> extends HostCfgDTO {
  key: string;
  data: DeviceProps;
  source?: string;
}

export abstract class BaseDeviceProps {
  vendor: string;
  device: string;
  created: number;
  updated: number;
  description: string;
  managementUrl: string;
  sourceAddress?: string;
  type?: string;
  licType?: string;
}

export interface DeviceType {
  deviceType: typeof STOR[number];
}

export interface HostCfgCommon extends DeviceType {
  onFormFinishFailed: (errorInfo: ValidateErrorEntity) => void;
  form: FormInstance<HostCfgItem<BaseDeviceProps>>;
  dispatch: AppDispatch;
  deferred?: Deferred<void>;
  modal?: {
    destroy: () => void;
    update: (configUpdate: ModalFuncProps) => void;
  };
}

export interface HostCfgDialogProps {
  itemToEdit?: HostCfgItem;
  common: HostCfgCommon;
  devices: HostCfgItem[];
}

export enum PROTO {
  HTTP = 'http', HTTPS = 'https'
}
