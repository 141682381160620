export enum Label {
  CUSTOM_GROUPS = 'Custom Group',
  DASHBOARD = 'Dashboard',
  GRAPH_TOP_ITEMS_TOOLTIP = 'Only specified number of items with TOP average will be present in initial graph load. Set 0 for no limit.',
  HOST_NAME_IP = 'Host name or IP',
  HOSTNAME_IP = 'Hostname/IP',
  LICENSE_LIMIT = 'Limit for maximum licensed number of configured devices reached.',
  REST_API = 'REST API',
  SMTP_NOTE = 'Note: Please configure SMTP server in Settings -> Configuration -> Application -> SMTP.',
  SNMP = 'SNMP',
  SSH = 'SSH',
  UNISPHERE_CLI = 'Unisphere CLI',
  XORMON_NG = 'Xormon NG'
}