import { CustomGroupItemGroup } from "@dto/customGroup.dto";
import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useContext, useState } from "react";
import { GLOB } from "src/util/Glob";
import { AllTagsContext } from "./AllTags";

type SelectOptionBasic = { value: string } & Pick<DefaultOptionType, 'label'>;
type SelectOption = SelectOptionBasic & { children?: SelectOptionBasic[] };

const filterOptions = (options: SelectOption[]) => options.filter(o => !o.value.startsWith('categories')).sort((a, b) => GLOB.naturalSort(a.label, b.label));

interface ProtocolSelectProps {
  selectProps?: SelectProps;
  tagSelectProps?: SelectProps;
  tagValueSelectProps?: SelectProps;
  value?: CustomGroupItemGroup['parents']['tags'][number]['tag_id'];
  onChange?: (value: CustomGroupItemGroup['parents']['tags'][number]['tag_id']) => void
}

export function TagSelect({ value, onChange, selectProps, tagSelectProps, tagValueSelectProps }: ProtocolSelectProps) {
  const { data, isLoading } = useContext(AllTagsContext);
  const [isLoadingPrev, setIsLoadingPrev] = useState(true);
  const [valuePrev, setValuePrev] = useState<typeof value>();
  const [tagSelectValue, setTagSelectValue] = useState<string>(!value ? 'default' : undefined);
  const [tagCategoryProps, setTagCategoryProps] = useState<{ options: SelectOption[], value: string }>();
  const options: SelectOption[] = [];

  if (!isLoading) {
    const tagId2Label: Record<string, string> = {};
    data.tags.forEach(({ include_self, ...tag }) => {
      tagId2Label[tag.tag_id] = tag.label;
      if (!include_self)
        options.push({
          label: tag.label,
          value: `categories-${tag.tag_id}`,
          children: []
        });
    });

    data.tagValues.forEach(tagValue => {
      if (tagValue.value == 'self') {
        options.push({
          label: tagId2Label[tagValue.tag_id],
          value: tagValue.tag_value_id,
        });

        return;
      }

      const tag = options.find(o => o.value == `categories-${tagValue.tag_id}`);
      tag.children.push({
        label: tagValue.value,
        value: tagValue.tag_value_id,
      });
    });

    if (isLoading !== isLoadingPrev) {
      setIsLoadingPrev(isLoading);
      setTagCategoryProps({ options: filterOptions(options), value });
    }

    // set value of the first select
    if (value !== valuePrev) {
      setValuePrev(value)
      const tag = options.find(o => o.value == value);
      if (tag) {
        setTagSelectValue('default');
        setTagCategoryProps({ options: filterOptions(options), value });
      } else {
        for (const tagOption of options) {
          if (!tagOption.children)
            continue;

          const tagValue = tagOption.children.find(o => o.value == value);
          if (tagValue) {
            setTagSelectValue(tagOption.value);
            setTagCategoryProps({ value: tagValue.value, options: tagOption.children });
            break;
          }
        }
      }
    }
  }

  const handleChangeTag = (valueTag: string, option: SelectOption) => {
    setTagSelectValue(valueTag);
    if (value)
      onChange?.(undefined);

    if (option.children) {
      setTagCategoryProps({ value: undefined, options: option.children.sort((a, b) => GLOB.naturalSort(a.label, b.label)) });
      return;
    }

    setTagCategoryProps({ options: filterOptions(options), value });
  };

  const handleChangeTagValue = (value: string, option: SelectOption) => {
    onChange?.(option.value);
    setTagCategoryProps(prev => ({ ...prev, value }));
  };

  const sharedClassName = selectProps?.className ? `${selectProps.className} ` : '';
  let tagClassName = tagSelectProps?.className ? `${sharedClassName} ${tagSelectProps.className} ` : `${sharedClassName} `;
  let tagValueClassName = tagValueSelectProps?.className ? `${sharedClassName} ${tagValueSelectProps.className} xm-error-able ` : `${sharedClassName} xm-error-able`;
  tagClassName += !tagSelectValue ? ' xm-unset' : ' xm-normal-state';
  tagValueClassName += !tagCategoryProps?.value ? ' xm-unset' : ' xm-normal-state';

  const firstSelectOption: SelectOption[] = [{
    label: 'No categories',
    value: 'default',
  }, ...options.filter(o => o.value.startsWith('categories')).sort((a, b) => GLOB.naturalSort(a.label, b.label))];

  return <>
    <Select<string, SelectOption>
      {...selectProps}
      {...tagSelectProps}
      showSearch
      optionFilterProp="label"
      className={tagClassName}
      value={tagSelectValue}
      loading={isLoading}
      size='small'
      dropdownMatchSelectWidth={false}
      onChange={handleChangeTag}
      options={firstSelectOption}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
    />
    <Select<string, SelectOption>
      {...selectProps}
      {...tagValueSelectProps}
      showSearch
      optionFilterProp="label"
      className={tagValueClassName}
      loading={isLoading}
      placeholder={'Tag'}
      size='small'
      dropdownMatchSelectWidth={false}
      onChange={handleChangeTagValue}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      {...tagCategoryProps}
    />
  </>;
}

export default TagSelect;