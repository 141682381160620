import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { useContext } from "react";
import { AdditionalApiParamsContext, RuleContext } from "../Rule";
import { AllItemsContext } from '../comp/AllItems';
import { makePreviewApiData } from "../comp/preview/CgPreviewHandler";
import { usePreviewModal } from "../comp/preview/Preview";

function RegexList() {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const { ruleIndex, parents, regex, linuxExclude } = useContext(RuleContext);
  const { preview } = useContext(AdditionalApiParamsContext);
  const [showModal, contextHolder] = usePreviewModal(linuxExclude);

  const handlePreview = (regex: string) => () => showModal({
    cls,
    subsystem,
    hw_type,
    data: makePreviewApiData(cls, hw_type, subsystem, parents, [regex], undefined, linuxExclude),
    additionalApiParams: preview?.items?.item,
  });

  return <>
    <Form.List name={[ruleIndex, 'regex']} >
      {(regexItems, { remove }) => (
        <div className="xm-rule-filter-regex">
          {regexItems?.map(({ key, name }) => (
            <span className="xm-regex-item" key={`regex-list-${key}`}>
              <Input.Group compact className={!regex[name].length || !subsystem ? 'xm-disabled-input' : 'xm-enable-input'}>
                <Form.Item name={name} rules={[{ required: true, message: 'Please enter Regex!' }, validatePattern]}>
                  <Input size="small" />
                </Form.Item>
                <Button
                  disabled={!regex[name].length || !subsystem || !isPatternValid(regex[name])}
                  size="small"
                  onClick={!(!regex[name].length || !subsystem) ? handlePreview(regex[name]) : undefined}
                  icon={<EyeOutlined />}
                />
              </Input.Group>
              <Button
                size="small"
                className={regex.length < 2 ? 'xm-disabled-delete' : 'hover-danger'}
                disabled={regex.length < 2}
                type='link'
                title='Remove rule'
                icon={<DeleteOutlined onClick={() => remove(name)} />}
              />
            </span>
          ))}
        </div>
      )}
    </Form.List>
    {contextHolder}
  </>;
}

export const validatePattern: ValidatorRule = {
  message: "Invalid pattern!",
  validator(_, value: string) {
    try {
      new RegExp(value);
    } catch {
      return Promise.reject(new Error());
    }

    return Promise.resolve();
  }
};

export function isPatternValid(patter: string) {
  try {
    new RegExp(patter);
  } catch {
    return 0;
  }

  return 1;
}

export default RegexList;