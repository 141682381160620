import { FC, PropsWithChildren, useEffect } from "react";
import { ThemeSwitcherProvider, useThemeSwitcher } from "react-css-theme-switcher";
import { GLOB } from "src/util/Glob";
import { ThemeUtil } from "src/util/ThemeUtil";

/**
   * Theme switcher styles
   */
const STYLE_MAP = {
  [ThemeUtil.NAMES.dark]: GLOB.isProdBuild() ? GLOB.getPublicUrl() + '/theme/style-dark.css' : ThemeUtil.NAMES.dark,
  [ThemeUtil.NAMES.light]: GLOB.isProdBuild() ? GLOB.getPublicUrl() + '/theme/style.css' : ThemeUtil.NAMES.light
} as const;

let defaultTheme: string;
if (ThemeUtil.isDarkMode()) {
  document.documentElement.style.colorScheme = 'dark';
  defaultTheme = ThemeUtil.NAMES.dark;
} else {
  document.documentElement.style.colorScheme = 'normal';
  defaultTheme = ThemeUtil.NAMES.light;
}

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {

  return (
    <ThemeSwitcherProvider defaultTheme={defaultTheme} themeMap={STYLE_MAP} >
      <UseTheme />
      {children}
    </ThemeSwitcherProvider>
  );
};

const UseTheme: FC = () => {
  const { switcher, themes, currentTheme, status } = useThemeSwitcher();

  useEffect(() => {
    ThemeUtil.themeSwitcher = switcher;
  }, []);

  useEffect(() => {
    if (ThemeUtil.isDarkMode()) {
      document.documentElement.style.colorScheme = 'dark';
    } else {
      document.documentElement.style.colorScheme = 'normal';
    }
  }, [currentTheme]);

  return null;
}