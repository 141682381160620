import { CardProps, FormItemProps, FormProps } from "antd";
import { ValidateMessages } from "rc-field-form/lib/interface";

export const BasicFormProps: FormProps = {
  labelAlign: "right",
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 17
  },
  validateTrigger: ['onChange', 'onBlur'] as string[],
} as const;

export const FormItemFullWrap: FormItemProps = {
  wrapperCol: { span: 24 }
}

export const FormValidateMessages: ValidateMessages = {
  required: "'${label}' is required"
};

export const HostCfgCardProps: CardProps = {
  className: "xm-hostcfg-card",
  size: "small",
  hoverable: true
}