import { Input } from "antd";
import { InputRef, PasswordProps } from "antd/lib/input";
import React, { FC, useState } from "react";

/**
 * Resettable password field, when API supports: NULL = keep previous value
 * @param props
 * @returns
 */
export const InputPassword: FC<PasswordProps & React.RefAttributes<InputRef>> = (props) => {

  const [readonly, setReadonly] = useState(true);

  function isKeepValue() {
    return props.value === null && !props["aria-required"];
  }

  return <Input.Password autoComplete="off" readOnly={readonly} onFocus={e => setReadonly(false)}
    title={isKeepValue() ? "Do not change this field's value to keep current password." : props.title}
    {...props}
    onChange={e => {
      if (e.target.value === '') {
        props.onChange?.({ ...e, target: { ...e.target, value: null } });
      }
      else
        props.onChange && props.onChange(e);
    }}
    placeholder={isKeepValue() ? 'Keep current by not modifying' : props.placeholder}
  />;

}