import { AlertLevel } from "@dto/alerting.dto";

export const ALERT_CONST = {
  CUSTOM_GROUP_ID: 'CUSTOM_GROUP',
  CUSTOM_GROUP_LABEL: 'Custom Group'
} as const;

export const ALERT_LEVEL = {
  undefined: 'undefined',
  info: 'info',
  warning: 'warning',
  minor: 'minor',
  major: 'major',
  critical: 'critical',
} as const;

export function getAlertLevelIndex(severity: string) {
  return Object.values(ALERT_LEVEL).indexOf(severity as AlertLevel);
}

// export const ALERT_TYPE = {
//   global: 'global',
//   personal: 'personal',
// } as const;

// export const ALERT_GROUP_TYPE = {
//   storage: 'storage',
//   default: 'default',
//   custom_group: 'custom_group',
// } as const;

export const ALERT_ITEM_TYPE = {
  list: 'list',
  regex: 'regex'
} as const;