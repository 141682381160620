import { UNIT } from "@const/subsystemMetricsResponse.constants";
import { IMetric } from "@dto/architecture.dto";
import { UNIT_PREFIX } from "@dto/constants/pageResponse.constants";
import { IMetricType, Metric, UnitPrefix } from "@dto/pageResponse.dto";
import { Unit } from "@dto/subsystemMetricsResponse.dto";

/**
 * Static unit conversion helper functions
 */
export abstract class UnitUtil {

  /**
  * Returns SI unit size prefix
  * @param power 0=kilo
  * @param bi true for exponent base 2 symbols
  */
  static getSiSymbol(power: number, bi: boolean) {
    if (power < 0) return '';
    return Object.values(UNIT_PREFIX)[power * 2 + (bi ? 1 : 0)] || (bi ? ('*2^' + (power + 1) * 10) : ('*10^' + (power + 1) * 3));
  }

  /**
     * Get number of digits after decimal point for specified unit
     * @param unit
     * @returns
     */
  static getUnitFractionDigits(unit: Unit) {
    if (!unit) return 0;
    switch (unit) {
      case UNIT.percent:
      case UNIT.cpu:
      case UNIT.io_per_second:
      case UNIT.errors_per_second:
      case UNIT.crc_errors_per_second:
      case UNIT.IOPS:
      case UNIT.pcs_errors:
      case UNIT.crc_errors:
      case UNIT.packets:
        return 0;
      case UNIT.cpu_core:
      case UNIT.bytes:
        //case UNIT.bytes_per_second:
        //case UNIT.net_per_second:
        return 1;
      default:
        return 2;
    }
  }

  /**
   * Safe round quotient to fraction digits based on unit
   * @param value number to round
   * @param magnitude divisor
   * @param unit unit to obtain fraction digits
   * @returns value if NaN
   */
  static roundValue(value, magnitude: number, unit: Unit, metric?: IMetricType) {
    if (!magnitude) magnitude = 1;
    return isNaN(value) || value == null ? value
      : (value / magnitude).toFixed(metric?.defaultPrecision ?? UnitUtil.getUnitFractionDigits(unit));
  }

  /**
   * Sizes the value to SI
   * @param value value to format
   * @param decimals fraction digits
   * @param bi true for base 2, false for base 10
   * @param unit postfix to SI
   * @returns null if NaN
   */
  static formatSI(value: number, decimals = 2, bi = false, metric: Partial<Metric> = { shortcut: 'B', unit: UNIT.bytes }) {
    if (value == null || Number.isNaN(value)) return null;
    const unit = metric?.shortcut;
    if (value === 0) return "0 " + unit;

    const k = bi ? 1024 : 1000;
    const dm = decimals < 0 ? 0 : decimals;
    if (value < k || UnitUtil.isBaseUnit(metric?.unit)) return value.toFixed(dm) + ' ' + unit;

    let i = Math.floor(Math.log(value) / Math.log(k));
    if (i < 0) i = 0;
    const idx = (Math.abs(i) - 1) * 2 + (bi ? 1 : 0);

    return (value / Math.pow(k, i)).toFixed(dm) + (idx < 0 ? ' ' : " " + Object.values(UNIT_PREFIX)[idx]) + unit;
  }

  /**
   * Checks if unit without SI
   * @param unit
   * @returns true if no SI prefixes before unit
   */
  static isBaseUnit(unit?: Unit) {
    switch (unit) {
      case UNIT.cpu:
      case UNIT.cpu_core:
      case UNIT.io_per_second:
      case UNIT.IOPS:
      case UNIT.millisecond:
      case UNIT.percent:
      case UNIT.rows:
      case UNIT.second:
      case UNIT.ratio:
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks if unit is base 2
   * @param unit
   * @returns
   */
  static isBiUnit(unit: Unit) {
    return unit === UNIT.bytes;
  }

  /**
   * Returns 1024 for base 2 unit, 1000 otherwise
   * @param unit
   * @returns
   */
  static getUnitBase(unit: Unit) {
    return UnitUtil.isBiUnit(unit) ? 1024 : 1000;
  }

  /**
   * Returns unit with SI prefix and applied magnitude
   * @param max size this value to SI
   * @param shortcut unit shortcut
   * @param unitEnum Unit enumeration
   * @returns
   */
  static sizeUnit(max: number, shortcut: string, unitEnum: Unit): { magnitude: number, unitSizedShortcut: string, unitSizePrefix: UnitPrefix } {
    const bi = UnitUtil.isBiUnit(unitEnum) ? 1 : 0;
    const biShortcut = (bi ? 'i' : '') + shortcut;
    if (shortcut?.includes('%') || UnitUtil.isBaseUnit(unitEnum))
      return { magnitude: 1, unitSizedShortcut: biShortcut, unitSizePrefix: null };
    if (UnitUtil.isMegaUnit(unitEnum))
      return { magnitude: 1_000_000, unitSizedShortcut: 'M' + biShortcut, unitSizePrefix: bi ? UNIT_PREFIX.mebi : UNIT_PREFIX.mega };
    if (UnitUtil.isGigaUnit(unitEnum))
      return { magnitude: 1_000_000_000, unitSizedShortcut: 'G' + biShortcut, unitSizePrefix: bi ? UNIT_PREFIX.gibi : UNIT_PREFIX.giga };

    const base = UnitUtil.getUnitBase(unitEnum);
    let i = Math.floor(Math.log(max) / Math.log(base));
    if (i < 0) i = 0;
    const magnitude = Math.pow(base, i);
    const unitSizePrefix = i > 0 ? Object.values(UNIT_PREFIX)[(i - 1) * 2 + bi] : null;
    const unitSizedShortcut = (i > 0 ? unitSizePrefix : '') + shortcut;
    return { magnitude, unitSizedShortcut, unitSizePrefix };
  }

  /**
   * Returns magnitude of SI prefix
   * @param unit unit to determine power base
   * @param unitPrefix SI prefix to get power exponent
   * @param multiplier override unit power base
   * @returns
   */
  static getMagnitude(unit: Unit, unitPrefix: UnitPrefix, multiplier?: number) {
    const base = multiplier || UnitUtil.getUnitBase(unit);
    const power = UnitUtil.getUnitPrefixPower(unitPrefix);
    return Math.pow(base, power);
  }

  /**
   * Returns value converted to/from SI prefix
   * @param value value to convert
   * @param prefix SI prefix to apply
   * @param unit unit to get power base
   * @param reverse true to remove SI prefix from value
   * @returns number
   */
  static convertSI(value: number, prefix: UnitPrefix, unit: Unit = UNIT.bytes, reverse = false) {
    const power = UnitUtil.getUnitPrefixPower(prefix);
    const magnitude = Math.pow(UnitUtil.getUnitBase(unit), power);
    return reverse ? (value * magnitude) : (value / magnitude);
  }

  /**
   * Returns value converted to SI prefix
   * @param value to convert
   * @param metric to obtain unit conversion info
   * @returns number
   */
  static convertSImetric(value: number, metric: IMetric) {
    return metric ? UnitUtil.convertSI(value, metric.defaultPrefix
      || UnitUtil.sizeUnit(0, metric.shortcut, metric.unit).unitSizePrefix, metric.unit)
      : value;
  }

  private static getUnitPrefixPower(prefix: UnitPrefix) {
    return prefix ? Math.floor(Object.values(UNIT_PREFIX).findIndex(v => v === prefix) / 2) + 1 : 0;
  }

  private static isMegaUnit(unit: Unit) {
    return unit === UNIT.bytes_per_second || unit === UNIT.net_per_second;
  }

  private static isGigaUnit(unit: Unit) {
    return unit === UNIT.hertz;
  }
}