import "@fontsource/roboto/latin-ext.css";
import "@fontsource/roboto/latin.css";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { onCLS, onFID, onLCP } from 'web-vitals';
import App from './App';
import './index.less';
import { store } from './redux/store';
import { ThemeProvider } from "./theme/ThemeProvider";

onCLS(console.log);
onFID(console.log);
onLCP(console.log);

createRoot(document.getElementById('root')).render(<Provider store={store}>

  <ThemeProvider>

    <App />

  </ThemeProvider>

</Provider>);

