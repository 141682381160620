import { ArrayItemType, Description, Required, Unrequired } from "@resolve/hostcfg.metadata";

export enum PROTO {
  HTTP = 'http', HTTPS = 'https'
}

export class Node {
  @Required
  alias: string
  @Required
  host: string;

  constructor(alias: string, host: string) {
    this.alias = alias;
    this.host = host;
  }
}

export class HostService {
  @Required
  @Description("Oracle RAC: Do not use SCAN IP in the configuration, add Virtual IP for each RAC node/instance separately. These instances should use the same listener service")
  host: string = undefined;
  @Required
  @Description("Use listener service of an instance, can be obtained by running 'lsnrctl status'")
  service: string = undefined;
  @Unrequired
  @ArrayItemType(String)
  pdbs: string[] = [''];

  constructor(host: string = '', service = '', pdbs = ['']) {
    this.host = host;
    this.service = service !== undefined ? service : "";
    this.pdbs = pdbs;
  }
}

export class DataGuardOld {
  hosts?: string[];
  instance?: string[];
  pdbs?: string[];

  public static getAsHostService(dg: DataGuardOld): HostService {
    return new HostService(
      dg.hosts ? dg.hosts[0] : "",
      dg.instance ? dg.instance[0] : "",
      dg.pdbs ? dg.pdbs : [""]
    );
  }
}

export class Sp {
  @Required
  host: string = undefined;
  @Unrequired
  sshPort: number = undefined;
  @Unrequired
  sshUsername: string = undefined;
  @Unrequired
  sshPassword: string = undefined;
  @Unrequired
  ftpUsername: string = undefined;
  @Unrequired
  ftpPassword: string = undefined;
  @Unrequired
  ftpPath: string = undefined;
}

export class OldSp {
  host: string;
  port: string;
  sshuser: string;
  sshpassword: string;
  ftpuser: string;
  ftppassword: string;
  ftppath: string;

  public static portSp(old: OldSp): Sp {
    return {
      host: old.host,
      sshPort: parseInt(old.port),
      sshUsername: old.sshuser,
      sshPassword: old.sshpassword,
      ftpUsername: old.ftpuser,
      ftpPassword: old.ftppassword,
      ftpPath: old.ftppath
    }
  }

}

export enum SnmpVersion {
  SNMPv1 = '1', SNMPv2c = '2c', SNMPv3 = '3'
}

export enum SnmpLevel {
  NOAUTH_NOPRIV = 'noAuthNoPriv', AUTH_NOPRIV = 'authNoPriv', AUTH_PRIV = 'authPriv'
}

export enum SnmpPrivProto {
  AES = 'AES', AES128 = 'AES128', AES256 = 'AES256'
}

export enum SnmpAuthProto {
  MD5 = 'MD5', SHA = 'SHA', SHA512 = 'SHA512'
}