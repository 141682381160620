import { Required, Unrequired } from "@resolve/hostcfg.metadata";
import { BaseDeviceProps } from "./deviceProps";

export class IBMCloudProps extends BaseDeviceProps {
    @Required
    apiKey: string;
    @Unrequired
    regions: IBMCloudVPCRegion[];
}

export interface IBMCloudVPCRegion {
    label: string;
    name: string;
    url: string;
}
