import { DefaultOptionType } from "antd/lib/select"
import { useReducer } from "react"

interface InitState {
  keepInput: boolean
  onSearch: boolean
  onIcon: boolean
  options: Options[]
  search: string
}

export interface Options extends DefaultOptionType {
  value: string
  key: string
  link: string
}

interface DispatchParams {
  type: Action
  payload?: string | Options[] | boolean
}

export enum Action {
  redirect = "redirect",
  onIcon = "toggle-icon-state",
  keepInput = "keep-input-visitable",
  hide = "hide-input",
  type = "input-val-change",
  setOpt = "set-options",
  leave = "leave-search",
  // resetText = "reset-text",
}

const initialState = {
  keepInput: false,
  onIcon: false,
  onSearch: false,
  search: "",
  options: [],
}

const reducer = (state: InitState, { type, payload }: DispatchParams): InitState => {
  if (type === Action.keepInput) {
    return {
      ...state,
      keepInput: true,
    }
  } else if (type === Action.onIcon) {
    return {
      ...state,
      onIcon: payload ? (payload as boolean) : !state.onIcon,
    }
  } else if (type === Action.type) {
    return { ...state, search: payload as string, options: [] }
  } else if (type === Action.setOpt) {
    return { ...state, options: payload as Options[] }
  } else if (type === Action.hide) {
    return {
      ...state,
      keepInput: false,
      onSearch: false,
    }
  } else if (type === Action.redirect) {
    return {
      ...state,
      keepInput: false,
    }
  }
  return state
}

export const useSearchReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { state, dispatch }
}
