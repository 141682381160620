import { IMetric } from "@dto/architecture.dto";
import { UnitPrefix } from "@dto/pageResponse.dto";
import { Unit } from "@dto/subsystemMetricsResponse.dto";
import { UnitUtil } from "@dto/util/UnitUtil";
import { DefaultOptionType } from "antd/lib/select";

/**
 *  Metric option for select component
 */
export interface MetricOption extends DefaultOptionType {
  text: string;
  shortcut: string;
  prefix: UnitPrefix;
  unit: Unit;
  metric: IMetric;
}

/**
 * Static unit helper rendering functions
 */
export abstract class UnitHelper {

  /**
   * Returns table column header
   * @param colName column name
   * @param unitSizedShortcut column unit shortcut
   * @returns
   */
  static formatUnitColHeader(colName: string, unitSizedShortcut: string) {
    const shortcut = colName === unitSizedShortcut || !unitSizedShortcut ? '' : ` [${unitSizedShortcut}]`;
    return colName + shortcut;
  }

  /**
   * Convert metric to select component option with appended unit shortcut
   * @param metric to extract
   * @returns MetricOption
   */
  static metric2option(metric: IMetric): MetricOption {
    const prefix = metric.defaultPrefix || UnitUtil.sizeUnit(0, metric.shortcut, metric.unit).unitSizePrefix;
    const prefixShortcut = (prefix || '') + metric.shortcut;
    return {
      value: metric.metric,
      text: metric.label,
      label: UnitHelper.getMetricOptionLabel(metric.label, prefixShortcut),
      shortcut: prefixShortcut,
      prefix,
      unit: metric.unit,
      metric: metric
    };
  }

  /**
   * Returns metric label with unit shortcut
   * @param metricLabel label of metric
   * @param shortcut unit shortcut to float at right
   * @returns JSX element
   */
  static getMetricOptionLabel(metricLabel: string, shortcut: string) {
    return <div>{metricLabel}<span className='xm-item-option-detail'>{shortcut}</span></div>;
  }

}