import { RELOAD_PART } from '@dto/constants/websocketMessage.constants';
import { MenuResponseDTO } from '@dto/menuResponse.dto';
import { DashboardContextMenu, MenuDataNode } from '@parts/dashboard/DashboardContextMenu';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { AxiosError } from 'axios';
import Tree from 'rc-tree/lib/Tree';
import React, { Key, useEffect, useMemo, useRef, useState } from 'react';
import { FaRegStar } from 'react-icons/fa';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { ContextState, CustomFolderContextMenu } from 'src/content/configuration/cg/CustomFolderContextMenu';
import { useWebSocketReloadGetRandomNumberOnReload } from 'src/hook/useWebSocketReload';
import { MenuNode } from 'src/model/MenuNode';
import { getPathUrl, itemsToNodes, mergeTree } from 'src/routing/NavigateRoute';
import { MenuService } from 'src/service/Menu';
import { getApi } from 'src/util/apiCalls';
import { RoutePath, isDevicesTree } from '../../data/Routes';
import { Log } from '../../service/Log';
import { GLOB } from '../../util/Glob';
import HistorySearch from './History/History';
import './SideNavBar.less';

export function mapParent(treeNodes: MenuNode[], parent: MenuNode) {
  if (!treeNodes) return;
  for (const tn of treeNodes) {
    //if (!tn.parent)
    tn.parent = parent;
    mapParent(tn.children, tn);
  }
}

function getGroupParent(node: MenuNode) {
  return MenuNode.getTopParent(node, (node) => !!node?.parent?.group_id);
}

export function getHwTypeParent(node: MenuNode): MenuNode {
  const result = getHwTypeParentRekur(node);
  if (result?.hwType) return result;
}
function getHwTypeParentRekur(node: MenuNode): MenuNode {
  if (!node) return;
  const parent = getHwTypeParent(node.parent);
  if (parent?.hwType) return parent;
  return node;
}

let newTree: MenuNode;
function spreadTree(node: MenuNode, tree: MenuNode) {
  if (!node.parent) {
    return (newTree = { ...tree });
  }
  if (node.parent) {
    const parent = spreadTree(node.parent, tree);
    let found: MenuNode = parent;
    if (parent?.children)
      parent.children = parent.children.map((n) => {
        const nod = n.key === node.key ? (found = { ...n }) : n;
        return nod;
      });
    return found;
  }
}

function makeLinkPath(node: MenuNode): string[] {
  if (!node?.parent)
    return [node.path];

  return [...makeLinkPath(node.parent), node.path];
}

let nav: NavigateFunction;
export function reloadPage() {
  const currentLocation = decodeURIComponent(location.pathname);
  const search = location.search;
  const hash = location.hash;
  nav(RoutePath.RELOAD, { replace: true });
  setTimeout(() => {
    nav(currentLocation + search + hash, { replace: true });
  });
}

let prevKey = '';
let selectedFromTree = false;

export const SideNavBar: React.FC = () => {
  const navigate = useNavigate();
  nav = navigate;
  const location = useLocation();
  const [tree, setTree] = useState<MenuNode>();
  const dashContextMenu = useMemo(() => new DashboardContextMenu(() => ({ options: tree.children as MenuDataNode[] }),
    ({ options }) => setTree({ ...tree, children: options }), item => item.isLeaf, null, null, reloadPage),
    [tree]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const treeRef = useRef<Tree>();
  const domRef = useRef<HTMLElement>();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const cgContextMenu = useMemo(() => new CustomFolderContextMenu(() => ({ folders: [tree] as MenuDataNode[] }),
    (state: ContextState) => setTree({ ...tree, children: [...state.folders[0].children] }), null, true), [tree]);
  const [state] = useState(() => ({ timeout: 0, selectFirst: false }));
  const wsReload = useWebSocketReloadGetRandomNumberOnReload(RELOAD_PART.menu);
  //const treeReload = useGetApiData<MenuItem[], MenuResponseDTO>(url, `Failed to refresh current tree.`, deps, [], 'SKIP_INIT');
  const aborter = useRef<AbortController>();
  const isSearch = getTreeTitle() === "Search";

  useEffect(() => {
    return () => {
      window.clearTimeout(state.timeout);
    }
  });

  useEffect(() => {
    if (wsReload) {
      void GLOB.menuService.init();
      if ([RoutePath.DASHBOARD, RoutePath.SETTINGS, RoutePath.PROFILE].some(rp => rp === GLOB.selectedClass)) return;

      const itemSearch = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')) + window.location.search;
      const tree = GLOB.getTree();
      setTree(null);
      getApi<MenuResponseDTO>(getPathUrl(GLOB.selectedClass, GLOB.selectedAllType, GLOB.selectedGroup, GLOB.selectedType, itemSearch),
        aborter.current.signal).then(response => {
          setTree(null);
          setTree({ ...tree, children: [...itemsToNodes(response.data.data)] });
        }, (reason: AxiosError) => Log.error('Failed to refresh current tree', reason))
    }
  }, [wsReload])

  useEffect(() => {
    aborter.current = new AbortController();
    //DataService.ws.onmessage = (event: MessageEvent<ReloadPart>) => {
    //if (event.data === 'menu') {
    //DataService.socket.on('reload', part => {
    //if (part === 'menu') {
    const interval = setInterval(() => {
      void GLOB.menuService.init();
      if ([RoutePath.DASHBOARD, RoutePath.SETTINGS, RoutePath.PROFILE].some(rp => rp === GLOB.selectedClass)) return;

      const itemSearch = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')) + window.location.search;
      const tree = GLOB.getTree();
      getApi<MenuResponseDTO>(getPathUrl(GLOB.selectedClass, GLOB.selectedAllType, GLOB.selectedGroup, GLOB.selectedType, itemSearch),
        aborter.current.signal).then(response => {
          mergeTree(tree.children, response.data.data, GLOB.selectedGroup);
          setTree({ ...tree, children: [...tree.children] });
        }, (reason: AxiosError) => Log.error('Failed to refresh current tree', reason));
    }, 1000 * 60 * 60);
    //}
    //);
    return () => {
      clearInterval(interval);
      //DataService.socket.off('reload');
      //DataService.ws.onmessage = null;
      aborter.current.abort();
    }
  }, [])

  // useEffect(() => {
  //   //TODO: move to data service
  //   if (!wsReload) return;
  //   console.log('wsReload', wsReload)
  //   GLOB.menuService.init();
  //   if (GLOB.selectedClass === RoutePath.DASHBOARD) return;

  //   const itemSearch = location.pathname.substring(location.pathname.lastIndexOf('/') + 1) + location.search;
  //   axios.get<MenuResponseDTO>(getPathUrl(GLOB.selectedClass, GLOB.selectedAllType, GLOB.selectedGroup, GLOB.selectedType, itemSearch),
  //     { signal: aborter.current.signal }).then(response => {
  //       mergeTree(tree.children, response.data.data, GLOB.selectedGroup);
  //       setTree({ ...tree });
  //     }, (reason: AxiosError) =>  Log.error('Failed to refresh current tree', reason));
  // }, [wsReload]);

  useEffect(() => {
    GLOB.setExpandedKeys = setExpandedKeys;
    GLOB.getExpandedKeys = () => expandedKeys;
    GLOB.scrollSelectedItemIntoView = () => {
      setExpandedKeys(prev => [...prev, ...MenuNode.getParentKeys(GLOB.selectedItem)].filter(GLOB.uniqueArrayFilter));
      setTimeout(() => {
        GLOB.waitForElm('.ant-tree-treenode-selected', domRef.current).then(el => {
          setTimeout(() => {
            domRef.current?.querySelector('.ant-tree-treenode-selected')?.scrollIntoView({ block: 'center' });
            // doesn't work: treeRef.current.scrollTo({ key: GLOB.selectedItem.key });
          }, GLOB.TIMEOUT_DEBOUNCE_SHORT);
        }, (reason: unknown) => reason);
      });
    };
  }, [expandedKeys]);

  useEffect(() => {
    if (selectedFromTree) {
      selectedFromTree = false;
    } else
      GLOB.scrollSelectedItemIntoView();
  }, [selectedKeys]);

  useEffect(() => {
    GLOB.setTree = setTree;
    GLOB.getTree = () => tree;
    GLOB.setSelectedKeys = setSelectedKeys;

    window.clearTimeout(state.timeout);

    if (!tree) return;

    if (prevKey !== tree?.key) {
      //if (!loadedKeys.every(k => isKeyWithinTree(k, tree)))
      setLoadedKeys([]);
      //if (!expandedKeys.every(k => isKeyWithinTree(k, tree)))
      setExpandedKeys([]);
    }

    if (tree?.url && !tree.children) {
      GLOB.treeLoaded.reset();
      getApi<MenuResponseDTO>(tree.url, aborter.current.signal).then(
        (response) => {
          setTree((prevTree) => {
            if (prevTree) spreadTree(prevTree, prevTree);
            else newTree = tree;
            newTree.children = response.data.data.map((mi) => MenuNode.menuItemToNode(mi));
            return newTree;
          });
          if (!GLOB.selectedItem) {
            setTimeout(() => {
              navigate(GLOB.selectedClass + GLOB.selectedType + GLOB.getItemPath(newTree.children[0]), { replace: true });
            });
          }
          GLOB.treeLoaded.resolve();
        },
        (reason: AxiosError) => {
          Log.error('Failed to get tree!', reason);
          GLOB.treeLoaded.reject();
        }
      );
    } else {
      GLOB.treeLoaded.resolve();
    }
    GLOB.treeLoaded.promise.then(() => {

      mapParent(tree?.children, tree);

      if (GLOB.selectedItem)
        if (prevKey !== tree?.key) {
          if (!GLOB.selectedItem.isLeaf && !GLOB.selectedItem.children)
            setLoadedKeys(expandedKeys.filter(k => k !== GLOB.selectedItem.key));
          else
            setLoadedKeys([...expandedKeys]);
          GLOB.scrollSelectedItemIntoView();
        } else if (!treeRef.current.props.selectedKeys.some(k => k === GLOB.selectedItem.key)) {
          GLOB.scrollSelectedItemIntoView();
        }

      prevKey = tree?.key as string;

    }, (reason: AxiosError) => console.error('Tree not loaded', reason));
  }, [tree]);

  function isWithinTree(node: MenuNode) {
    if (node === tree) return true;
    if (node.parent) return isWithinTree(node.parent);
    return false;
  }

  // function isKeyWithinTree(key: string, node: MenuNode) {
  //   if (!node || !key) return false;
  //   if (node.key === key) return true;
  //   if (node.children) {
  //     for (const child of node.children) {
  //       if (isKeyWithinTree(key, child)) return true;
  //     }
  //   }
  //   return false;
  // }

  function onLoadData(node: MenuNode) {
    if (!isWithinTree(node)) {
      window.clearTimeout(state.timeout);
      state.timeout = window.setTimeout(() => {
        Log.warn(`Node ${node.key} not part of tree ${tree.key}!`);
      }, GLOB.TIMEOUT_DEBOUNCE_SHORT);
      setExpandedKeys(prev => prev.filter(k => k !== node.key));
      return Promise.reject(new Error(`Node ${node.key} is not part of the tree ${tree.key}!`));
    }
    return new Promise<void>((resolve, reject) => {
      if (node.children && (node.children.length || !node.url)) {
        spreadTree(tree, tree);
        setTree(newTree);
        setLoadedKeys(prev => [...prev, node.key as string]);
        setExpandedKeys(prev => [...prev, node.key as string]);
        resolve();

        return;
      }
      GLOB.treeLoaded.reset();
      if (node.url) {
        getApi<MenuResponseDTO>(node.url, aborter.current.signal).then(
          (response) => {
            setLoadedKeys(prev => [...prev, node.key as string]);
            setTree((prevTree) => {
              const expanded = spreadTree(node, prevTree);
              const group = GLOB.selectedAllType === RoutePath.GROUP ? MenuNode.getGroupPathSegment(getGroupParent(node)) : '';
              expanded.children = response.data.data.map((mi) => MenuNode.menuItemToNode(mi, group));
              if (GLOB.selectedClass === RoutePath.CUSTOM_GROUPS || GLOB.selectedClass === RoutePath.DASHBOARD)
                expanded.children.sort(MenuService.getFolderSorter(false));
              mapParent(expanded.children, expanded);
              resolve();
              setTimeout(() => {
                selectFirst(expanded.children);
                setExpandedKeys(prev => [...prev, node.key as string]);
              });
              const menu = GLOB.getMenu();
              const root = menu.find(mn => mn.path === GLOB.selectedClass);
              let menuTree = root;
              if (GLOB.selectedType && !GLOB.selectedAllType) {
                menuTree = root.children.find(mn => mn.path === GLOB.selectedType);
              }
              if (menuTree) {
                menuTree.children = newTree.children;
                setTimeout(() => {
                  GLOB.setMenu([...menu]);
                });
              }
              return newTree;
            });
          },
          (reason: AxiosError) => {
            Log.error('Failed to get sub-menu!', reason);
            GLOB.treeLoaded.reject();
            reject(new Error('Failed to get sub-menu'));
            // node.title = <span><ExclamationOutlined title='Failed to expand' />{node.title as ReactNode}</span>;
            // setTree({ ...tree, children: [...tree.children] });
            setLoadedKeys(prev => [...prev, node.key as string]);
          }
        );
      }
    });
  }

  const onExpand = (
    keys: Key[],
    info: {
      node: EventDataNode<DataNode>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    }
  ) => {
    setExpandedKeys((info.expanded ? keys : keys.filter(k => k !== info.node.key)) as string[]);
  };

  const onSelect = (
    keys: React.Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: EventDataNode<DataNode>;
      selectedNodes: MenuNode[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const selected = info.selectedNodes[0];
    let pathNew: string[];

    if (selected.path && selected.isLeaf) {
      pathNew = [GLOB.selectedClass, GLOB.selectedAllType, GLOB.selectedType, selected.path];
      if (MenuNode.getTopParent(selected).title === "Logs")
        pathNew = [GLOB.selectedClass, ...makeLinkPath(selected)];
    } else if (selected.url && selected.isLeaf) {
      if (GLOB.selectedType && !["/lancisco", "/lanothers"].includes(GLOB.selectedType)) {
        const cls = GLOB.getMenu().find(mn => mn.path === GLOB.selectedClass);
        const itemIdx = selected.url.lastIndexOf('/');
        const item = selected.url.substring(itemIdx);
        const typeIdx = selected.url.lastIndexOf('/', itemIdx - 1);
        const typePath = selected.url.substring(typeIdx, itemIdx);
        const type = typePath.substring(1);

        if (isDevicesTree(GLOB.selectedAllType)) {
          let groupPath = '';
          if (GLOB.selectedAllType === RoutePath.GROUP) {
            const top = getGroupParent(selected);
            if (!top.isLeaf)
              groupPath = '/' + top.itemId;
          }
          const device = getHwTypeParent(selected);
          pathNew = [GLOB.selectedClass, GLOB.selectedAllType, groupPath, device ? ('/' + device.titleText) : '', item];
        } else if (cls.children.some(mn => mn.path === typePath || mn.hwType === type)) {
          pathNew = [GLOB.selectedClass, GLOB.selectedAllType, typePath, item];
        } else if (cls.path === typePath) {
          pathNew = [GLOB.selectedClass, GLOB.selectedAllType, item];
        } else {
          const clsOther = GLOB.getMenu().find(mn => mn.children.some(c => c.path === typePath));
          pathNew = [clsOther.path, typePath, item];
        }

      } else {
        let groupPath = '';
        if (GLOB.selectedAllType === RoutePath.GROUP) {
          const top = getGroupParent(selected);
          if (!top.isLeaf)
            groupPath = '/' + top.itemId;
        }
        const device = getHwTypeParent(selected);
        const deviceTypePath = GLOB.selectedClass === RoutePath.CUSTOM_GROUPS || GLOB.selectedClass === RoutePath.DASHBOARD || !device ? '' :
          ('/' + (isDevicesTree(GLOB.selectedAllType) ? device.titleText : device.hwType));
        pathNew = [GLOB.selectedClass, GLOB.selectedAllType, groupPath, deviceTypePath, GLOB.getItemPath(selected)];
      }

    } else {
      //Log.error('Navigation failed!', info);
    }

    if (pathNew) {
      const path = pathNew.join('');
      const pathCurrent = decodeURIComponent(location.pathname + location.search);
      selectedFromTree = true;
      if (path === pathCurrent) {
        reloadPage();
      } else {
        navigate(path + location.hash);
      }
    }
  };

  const titleRender = (node: MenuNode) => {
    let title = node.title;
    if (GLOB.selectedClass === RoutePath.DASHBOARD && !MenuNode.isDashExcluded(node)) {
      if (!node.titleText && !node.divider)
        node.titleText = node.title as string;

      title = dashContextMenu.createLabel(node as MenuDataNode);
    } else if (GLOB.selectedClass === RoutePath.CUSTOM_GROUPS) {
      if (!node.titleText)
        node.titleText = node.title as string;

      title = cgContextMenu.createLabel(node as MenuDataNode);
    } else {
      title = node.titleText ?? title;
    }

    switch (node.styleClass) {
      case 'bold':
        return <strong>{title}</strong>;
      case 'star':
        return <div style={{ display: 'inline-flex' }}>{title}<FaRegStar style={{ marginLeft: '0.25em' }} title='Default dashboard' /></div>;
      default:
        return title;
    }
  };

  function getTreeTitle() {
    if (GLOB.selectedClass === RoutePath.DASHBOARD)
      return dashContextMenu.createLabel({ key: DashboardContextMenu.ROOT, itemId: DashboardContextMenu.ROOT, titleText: tree?.title as string });
    if (GLOB.selectedClass === RoutePath.CUSTOM_GROUPS) {
      tree && (tree.itemId = CustomFolderContextMenu.ROOT);
      CustomFolderContextMenu.selectRef = null;
      return cgContextMenu.createLabel({ key: CustomFolderContextMenu.ROOT, itemId: CustomFolderContextMenu.ROOT, titleText: tree?.title as string });
    }
    return tree?.title;
  }

  function selectFirst(children: MenuNode[]) {
    if (state.selectFirst) {
      state.selectFirst = false;
      const toSelect = MenuNode.selectFirst(children);
      if (toSelect && GLOB.selectedItem !== toSelect) onSelect(null, { selectedNodes: [toSelect], event: 'select', nativeEvent: null, node: null, selected: null });
    }
  }

  const searchHistoryLimit = GLOB?.userInfo?.configuration?.searchHistoryLimit
  const userId = GLOB?.userInfo?.user_id

  return (
    <nav className={`left-tree ${isSearch ? "left-tree-height" : ""}`} ref={domRef}>
      <h3><>{getTreeTitle()}</></h3>
      <DirectoryTree<MenuNode>
        style={{ height: "35px" }}
        ref={treeRef}
        blockNode={true}
        defaultExpandParent={true}
        expandedKeys={expandedKeys}
        loadData={onLoadData}
        loadedKeys={loadedKeys}
        onExpand={onExpand}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        showIcon={false}
        showLine={false}
        titleRender={titleRender}
        treeData={tree?.children?.length ? tree.children : []}
        onClick={(e, node) => {
          if (node.isLeaf || node.expanded) return;
          state.selectFirst = true;
          if (node.children?.length > 0) {
            selectFirst(node.children as MenuNode[]);
          }
        }}
      />
      {isSearch && searchHistoryLimit && (
        <HistorySearch tree={tree} historyState={searchHistoryLimit} userId={userId} />
      )}
      {/* <div className='license-footer'>
        <a href='https://xorux.com/' target="_blank">XORUX</a> apps&nbsp;family {appGlobals.info?.backend.free &&
          <a href='https://xormon.com/support.php' target="_blank">Free&nbsp;Edition</a>}
      </div> */}
    </nav>
  );
};
