import React from "react";

interface Change {
  version: string;
  content: React.ReactElement
}

/**
 * Array ordered by version
 */
export const CHANGES: Change[] = [
  {
    version: '1.7',
    content: <div>
      <h3>v1.7.0 (10th Dec 2024)</h3>
      The main enhancements include support for new monitored devices and further feature improvements.
      <h4>Support for new technologies</h4>
      <ul>
        <li>IBM Cloud</li>
        <li>IBM Storage Protect (formerly IBM Spectrum Protect, Tivoli Storage Manager (TSM))</li>
        <li>IBM DS8000</li>
        <li>Veritas NetBackup</li>
        <li>Ceph</li>
        <li>ExaGrid</li>
        <li>IBM Storage Defender Data Protect</li>
      </ul>
      <h4>Feature enhancements</h4>
      <ul>
        <li>UI <a href="https://xormon.com/dark-mode.php" target="_blank">dark mode</a> as a user option</li>
        <li>UI search per IP</li>
        <li><a href="https://xormon.com/CFG-Tracker.php" target="_blank">CFG Tracker</a>: easy tracking all configuration changes mainly on VMs, containers and volumes</li>
        <li>HPE 3PAR / Alletra 9000: copy services (mirroring) implemented</li>
        <li>EMC Unity: total is now block + FS (before FS was not considered in total)</li>
        <li>Dell EMC PowerMAX: SAN Topology was implemented</li>
        <li><a href="https://xormon.com/IBM-Power-SAN-Topology.php" target="_blank">IBM Power Topology</a>: LPAR ➡ VIO ➡ FC adapter ➡ SAN switch</li>
        <li>Nutanix: capacity per cluster, host and disk</li>
        <li><a href="https://xormon.com/VMware-Topology.php" target="_blank">VMware Topology</a>: schemas are presented on cluster, datastore and VM levels</li>
        <li>VMware: TOP Datastore: Used Space %</li>
        <li>Microsoft Azure agent 2 support</li>
        <li><a href="https://xormon.com/Nutanix-Topology.php" target="_blank">Nutanix Topology</a> diagrams</li>
        <li>VM migration graphs for IBM Power System, Nutanix and Proxmox</li>
        <li>Alerting: new API for user defined connections to 3rd party tools</li>
        <li>Graphs: switch between stacked and line graphic mode</li>
        <li>Filesystem global tables per technology</li>
        <li>ACL enhancements, possibility to export/import ACL rules, rules are available via the REST API</li>
        <li>Dell EMC Isilon: add node table to configuration page</li>
        <li>Dell Compellent: add back-end statistics for Pool (IO, DATA)</li>
      </ul>
    </div>
  },
  {
    version: '1.6',
    content: <div>
      <h3>v1.6.0 (19th Sep 2024)</h3>
      The main enhancements include support for new monitored devices and further feature improvements.
      <h4>Support for new technologies</h4>
      <ul>
        <li>IBM Power Enterprise Pools 2.0 (PEP2.0)</li>
        <li>oVirt / Red Hat Virtualization (RHV) / OLVM</li>
        <li>Solaris CDOM / LDOM / Zone</li>
        <li>Broadcom (Brocade) SANnav</li>
        <li>Synology</li>
        <li>Dell EMC² Unity</li>
        <li>DataCore SANsymphony</li>
        <li>Huawei OceanStor Pacific</li>
        <li>Huawei OceanStor Pacific NAS</li>
        <li>Huawei OceanProtect Backup Storage</li>
      </ul>
    </div>
  },

] as const;