import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { SettingsItem, SettingsResponseDTO } from "@dto/settings.dto";
import axios, { AxiosError } from "axios";
import { putApi } from "src/util/apiCalls";
import { API_URL } from "../data/Api";
import { Log } from "./Log";

const URL = API_URL.CONFIGURATION + '/settings';

export function getSettings(prefix?: string, controller?: AbortController) {
  const promise = axios.get<SettingsResponseDTO>(URL, { signal: controller?.signal, params: { prefix: prefix } });
  promise.catch((reason: AxiosError) => Log.error('Failed to get settings', reason));
  return promise;
}

export function saveSettings(values: SettingsItem) {
  const promise = putApi<BasicResponseDTO, SettingsItem>(URL, values);
  promise.catch((reason: AxiosError) => Log.error('Failed to save settings!', reason));
  return promise;
}