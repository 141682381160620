import { TIMESERIES_TOP_ORDER } from "@const/timeseriesRequest.contants";
import { TimeseriesTopOrder } from "@dto/timeseriesRequest.dto";
import { Button, InputNumber, Modal, ModalFuncProps, Select, Slider } from "antd";
import { FC, useMemo, useState } from "react";
import { AsyncButton } from "../../asyncButton/AsyncButton";

interface TopItemsModalState extends ModalFuncProps {
  order: TimeseriesTopOrder;
  count: number;
  maxCount: number;
  onConfirm: (count: number, order: TimeseriesTopOrder) => Promise<void>;
  onCancel: (...args: unknown[]) => unknown;
}

export const TopItemsModal: FC<TopItemsModalState> = ({ maxCount, onConfirm, ...props }) => {
  const currentCount = useMemo(() => Math.min(maxCount, props.count), [props.count]);
  const [nextItems, setNextItems] = useState(() => Math.min(maxCount, currentCount + 10));
  const [order, setOrder] = useState(props.order);

  return <Modal title="Load items" closable={false} footer={<>
    <Button onClick={e => props.onCancel()}>Cancel</Button>
    <AsyncButton type='primary' onClickAsync={e => onConfirm(nextItems, order)}>Load {nextItems}</AsyncButton>
    <AsyncButton type='primary' onClickAsync={e => onConfirm(maxCount, order)}>Load all {maxCount}</AsyncButton>
  </>} {...props}>
    <label>Top items to load: <InputNumber<number> min={1} max={maxCount} value={nextItems} onChange={setNextItems} /></label>
    <label style={{ marginLeft: '1em' }}>Order by: <Select defaultValue={order} onChange={(value, option) => setOrder(value)}
      options={Object.values(TIMESERIES_TOP_ORDER).map(o => ({ value: o, label: o.toUpperCase() }))} getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement} />
    </label>
    <label style={{ margin: '0.5em 0 0 1em', color: 'gray', float: 'right', }}>Currently loaded: {currentCount || maxCount}</label>
    <Slider min={1} max={maxCount} value={nextItems} onChange={setNextItems}
      marks={{
        //[state.loadedItems]: 'Current ' + state.loadedItems,
        [maxCount]: <span style={{ marginLeft: '-0.5em' }}>{maxCount}</span>
      }} />
  </Modal>;
}