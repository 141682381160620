import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { HostCfgDTO, HostCfgResponseDTO, HostCfgsResponseDTO } from "@dto/hostCfgResponse.dto";
import { MicroserviceResponseDTO } from "@dto/microserviceResponse.dto";
import axios, { AxiosError } from "axios";
import { postApi, putApi } from "src/util/apiCalls";
import { API_URL } from "../../../data/Api";
import { Log } from "../../../service/Log";
import { HostCfgItem } from "./ConfigurationTypes";

export function getHostCfgList(clazz: string, hwType?: string) {
  const promise = axios.get<HostCfgsResponseDTO>(API_URL.CONFIGURATION + '/hostcfg?' + (hwType ? 'hw_type=' + hwType : 'class=' + clazz));
  promise.catch((reason: AxiosError) => Log.error('Failed to get host configurations for ' + clazz));
  return promise;
}

export function deleteHostCfg(cfg: HostCfgItem) {
  const promise = axios.delete<BasicResponseDTO>(API_URL.CONFIGURATION + '/hostcfg/' + cfg.hostcfg_id);
  promise.catch((reason: AxiosError) => Log.error('Failed to delete host configuration ' + cfg.label, reason));
  return promise;
}

export function createHostCfg(cfg: HostCfgItem) {
  const promise = postApi<HostCfgResponseDTO, HostCfgDTO>(API_URL.CONFIGURATION + '/hostcfg', cfg, { params: { source: cfg.source } });
  promise.catch((reason: AxiosError) => Log.error('Failed to create new configuration ' + cfg.label, reason));
  return promise;
}

export function updateHostCfg(cfg: HostCfgDTO) {
  const promise = putApi(API_URL.CONFIGURATION + '/hostcfg/' + cfg.hostcfg_id, cfg);
  promise.catch((reason: AxiosError) => {
    Log.error(`Failed to update ${cfg.label} configuration!`, reason);
  });
  return promise;
}

export function fetchData(cfg: HostCfgItem) {
  return postApi<MicroserviceResponseDTO>(`${API_URL.MICROSERVICES}/${cfg.hw_type}/fetch?hostcfg_id=${cfg.hostcfg_id}`).then(response => {
    Log.info(cfg.label + ' fetch finished.');
  }, (reason: AxiosError) => Log.error(`Failed to fetch ${cfg.label} data!`, reason));
}