import { HwTypeKey } from "@dto/constants/hwTypeKey.constants";
import { IBMCloudProps, IBMCloudVPCRegion } from "@dto/hostcfgs/ibmcloud.data";
import { Card, Checkbox, Form } from 'antd';
import { ReactNode } from 'react';
import { InputPassword } from "src/component/inputPassword/inputPassword";
import { HostCfgCardProps } from '../../../../../data/props';
import { HostCfgItem } from '../../ConfigurationTypes';
import { AbstractPlatform } from '../AbstractPlatform';

export default class IBMCloud extends AbstractPlatform<IBMCloudProps> {

  protected installUrl = "https://xormon.com/IBMCloud-monitoring-installation.php";
  private regions: Record<string, IBMCloudVPCRegion> = {
    "us-east": {
      label: "us-east",
      name: "Washington DC",
      url: "https://us-east.iaas.cloud.ibm.com/v1"
    },
    "us-south": {
      label: "us-south",
      name: "Dallas",
      url: "https://us-south.iaas.cloud.ibm.com/v1"
    },
    "ca-tor": {
      label: "ca-tor",
      name: "Torronto",
      url: "https://ca-tor.iaas.cloud.ibm.com/v1"
    },
    "br-sao": {
      label: "br-sao",
      name: "Sao Paulo",
      url: "https://br-sao.iaas.cloud.ibm.com/v1"
    },
    "eu-de": {
      label: "eu-de",
      name: "Frankfurt",
      url: "https://eu-de.iaas.cloud.ibm.com/v1"
    },
    "eu-es": {
      label: "eu-es",
      name: "Madrid",
      url: "https://eu-es.iaas.cloud.ibm.com/v1"
    },
    "eu-gb": {
      label: "eu-gb",
      name: "London",
      url: "https://eu-gb.iaas.cloud.ibm.com/v1"
    },
    "au-syd": {
      label: "au-syd",
      name: "Sydney",
      url: "https://au-syd.iaas.cloud.ibm.com/v1"
    },
    "jp-osa": {
      label: "jp-osa",
      name: "Osaka",
      url: "https://jp-osa.iaas.cloud.ibm.com/v1"
    },
    "jp-tok": {
      label: "jp-tok",
      name: "Tokyo",
      url: "https://jp-tok.iaas.cloud.ibm.com/v1"
    },
  }

  constructor(props) {
    super(props, HwTypeKey.IBMCLOUD, IBMCloudProps);

  }

  protected getFormItems(initialCfg: HostCfgItem<IBMCloudProps>): ReactNode {
    return <>
      {this.commonCard()}


      <Card {...HostCfgCardProps} title="API">

        <Form.Item label={"API Key"} name={this.path(t => t.data.apiKey)} rules={[{ required: !this.isEdit(), message: 'Please input API Key!' }]} >
          <InputPassword />
        </Form.Item>
        <Card {...HostCfgCardProps} title="Regions">
          <Form.Item name={this.path(t => t.data.regions)} labelCol={{ span: null }} wrapperCol={{ span: 24 }}>
            <Checkbox.Group style={{ marginLeft: '1em' }}>
              {(Object.values(this.regions) || this.state.cfgInEdit?.data.regions)?.map(value => <div key={value.name}>
                <Checkbox value={value}>{value.name}</Checkbox>
              </div>)}
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
    </>;
  }
}
