import { Button, Modal, Progress, Spin } from "antd";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { Log } from "../../service/Log";
import { GLOB } from "../../util/Glob";

type ProgressStatus = "normal" | "exception" | "active" | "success";

export function saveBlobToFile(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const downlink = document.createElement('a');
  downlink.style.display = 'none';
  downlink.href = url;
  downlink.download = fileName;
  document.body.append(downlink);
  downlink.click();

  return GLOB.timeoutPromise(() => {
    URL.revokeObjectURL(url);
    downlink.remove();
  });
}

export const ApiDownloader: FC<{ request: AxiosRequestConfig, fileName: string }> = ({ request, fileName, ...props }) => {

  const [startTime] = useState(() => new Date().getTime());
  const [status, setStatus] = useState<ProgressStatus>('normal');
  const [percent, setPercent] = useState(0);
  const [info, setInfo] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const aborter = useRef<AbortController>();

  useEffect(() => {
    return () => {
      aborter.current?.abort();
    }
  }, []);

  useEffect(() => {
    setPercent(0);
    setStatus('normal');
    if (request && !modalVisible) {
      setModalVisible(true);
      download();
    }
  }, [request]);

  function download() {
    setStatus('active');
    aborter.current?.abort();
    aborter.current = new AbortController();
    axios.request<Blob>({
      ...request, responseType: 'blob', signal: aborter.current.signal,
      onDownloadProgress: (e) => {
        const percent_complete = Math.floor((e.loaded / e.total) * 100);
        setPercent(percent_complete);

        const duration = (new Date().getTime() - startTime) / 1000;
        const bps = e.loaded / duration;

        const kbps = Math.floor(bps / 1024);

        const time = (e.total - e.loaded) / bps;
        const seconds = Math.floor(time % 60);
        const minutes = Math.floor(time / 60);

        setInfo(`${kbps} Kbps - ${minutes} min ${seconds} sec remaining`);
      }
    }).then(response => {
      setInfo('File downloaded');
      setStatus('success');
      setPercent(100);

      void saveBlobToFile(response.data, fileName).then(() => setModalVisible(false));

    }, (reason: AxiosError) => {
      Log.error('Download failed!', reason);
      setStatus('exception');
    });
  }

  return <Modal bodyStyle={{ textAlign: 'center' }} open={modalVisible} closable={false} title={'Downloading ' + fileName}
    keyboard={false}
    footer={[<Button key='cancel' onClick={() => {
      aborter.current?.abort();
      setModalVisible(false);
    }}>{status === 'active' ? 'Cancel' : 'Close'}</Button>]}>
    {percent === 0 ? <Spin size="large" /> : <Progress type="circle" percent={percent} status={status} />}
    <div>{info}</div>
  </Modal>;
}
