import { IHwTypeBase } from '@dto/architecture.dto';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/data/Routes';
import { GLOB } from 'src/util/Glob';
import { ColProps, GridScope } from '../../../component/grid/Grid';
import { HostCfgItem } from './ConfigurationTypes';

interface TableConfigurationProps {
  columns: ColProps<HostCfgItem>[];
  dataSource: readonly HostCfgItem[];
  subTypeLimit?: {
    limit: number;
    hwType: IHwTypeBase;
    key: string;
  };
}

export default function TableConfiguration(props: Readonly<TableConfigurationProps>) {

  const { columns, dataSource, subTypeLimit } = props;
  const [gs] = useState(() => new GridScope<HostCfgItem>());

  return <div>
    <gs.Grid className='xm-table-fitx'
      dataSource={dataSource}
      renderProps={{ noClipboard: true }}
      pagination={false}
      style={{ position: "relative", left: "0px" }}
      onRow={record => ({ className: (record.disabled ? 'text-gray ' : '') + (record.limited ? 'text-danger' : '') })}
      columns={columns}
    />
    {dataSource.some(hci => hci.limited) && <div className='xm-license-limit'>
      {GLOB.getBackendInfo().backend.free ? <>
        <strong>Warning:</strong> Free Edition is limited to {dataSource.filter(hci => !hci.limited && !hci.disabled).length} active devices.<br />
        Consider upgrading to the <a href="https://xormon.com/support-ng.php" target="_blank">Enterprise Edition</a>.
      </>
        : subTypeLimit ? <>
          You have reached maximum number ({subTypeLimit.limit}) of {subTypeLimit.hwType.label} [{subTypeLimit.key}] covered by your license.<br />
          Please check <Link to={RoutePath.SETTINGS + RoutePath.HELP + RoutePath.LICENSE}>license</Link> or contact <Link to={RoutePath.SETTINGS + RoutePath.HELP + RoutePath.SUPPORT}>support</Link>.
        </>
          : <>
            <strong>License warning:</strong> You have reached the limit of active devices.<br />
            Please contact <Link to={RoutePath.SETTINGS + RoutePath.HELP + RoutePath.SUPPORT}>support</Link> if you need to add more devices.
          </>}
    </div>}
  </div>;
}
