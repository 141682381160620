import { useEffect, useState } from "react";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";

const wsByUrl: Record<string, { count: number, ws: WebSocket, callbacks: Set<(data) => void> }> = {};

const useWebSocketSingleton = <T>(url: string) => {
  const [data, setData] = useState<T>();

  useEffect(() => {
    let wsc = wsByUrl[url];
    if (!wsc) {
      wsc = wsByUrl[url] = { count: 0, ws: new WebSocket(`${GLOB.SERVER_WS_URL}${url}`), callbacks: new Set() };
      wsc.ws.onerror = (ev) => Log.error(`WebSocket ${url} error`, ev);
      wsc.ws.onmessage = (ev) => {
        const data: T = JSON.parse(ev.data as string);
        wsc.callbacks.forEach(c => c(data));
      };
    }
    wsc.count++;
    wsc.callbacks.add(setData);

    return () => {
      wsc.count--;
      if (wsc.count <= 0 || wsc.ws.readyState !== WebSocket.OPEN) {
        wsc.ws.close();
        delete wsByUrl[url];
      } else {
        wsc.callbacks.delete(setData);
      }
    }
  }, []);

  return data;
}

export default useWebSocketSingleton;