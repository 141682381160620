
export enum ClassKey {
    STORAGE = 'storage',
    VIRTUALIZATION = 'virtualization',
    CLOUD = 'cloud',
    DATABASE = 'database',
    SAN = 'san',
    CONTAINER = 'container',
    LAN = 'lan',
    BACKUP = 'backup'
}

export const classDefinitionLabel = {
    [ClassKey.STORAGE]: 'Storage',
    [ClassKey.VIRTUALIZATION]: 'Virtualization',
    [ClassKey.CLOUD]: 'Cloud',
    [ClassKey.DATABASE]: 'Database',
    [ClassKey.SAN]: 'San',
    [ClassKey.LAN]: 'Lan',
    [ClassKey.CONTAINER]: 'Container',
    [ClassKey.BACKUP]: 'Backup',
}