import { IHwTypeBase, Item, ItemsResponseDTO, Subsystem, SubsystemsResponseDTO } from '@dto/architecture.dto';
import { SearchItemResponseDTO } from '@dto/searchResponse.dto';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { RoutePath } from "src/data/Routes";
import { MenuNode } from "src/model/MenuNode";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import { getAllSubsystems } from '../tools/alerting/alertingService';
// : [AxiosResponse<SearchItemResponseDTO | ItemsResponseDTO>, SubsystemsResponseDTO]
const useSearch = (path: string, parm: string, api: string): [Item[], boolean] => {
  const [data, setData] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (path !== RoutePath.SEARCH)
      return () => changeTree([]);

    const controller = new AbortController();
    setLoading(true);
    const onResolve = ([it, hwTypes, subsystems]: [AxiosResponse<SearchItemResponseDTO | ItemsResponseDTO>, IHwTypeBase[], AxiosResponse<SubsystemsResponseDTO>]) => {
      const items = Array.isArray(it.data.data) ? it.data.data : it.data.data.items;
      const searched: MenuNode = {
        key: 'search',
        selectable: false,
        isLeaf: true,
        title: treeTitleText(parm)
      }

      const node: MenuNode = {
        key: 'found',
        selectable: false,
        isLeaf: true,
        title: (items) ? "Found: " + (items.length) : "Found: 0"
      };

      const hwTypeLabel = getLabel('hw_type', hwTypes);
      const subsystemLabel = getLabel('subsystem', subsystems.data.data);

      changeTree(parm ? [node, searched] : [node]);
      setData(items.map(item => {
        const filterSubs: FilterFunc = ({ hw_type }) => (item.hw_type === hw_type);
        return { ...item, hw_type: hwTypeLabel(item.hw_type), subsystem: subsystemLabel(item.subsystem, filterSubs) };
      }));
    };

    const onReject = (reason: AxiosError) => {
      changeTree([{
        key: 'found',
        selectable: false,
        isLeaf: true,
        title: "Found: 0"
      }]);

      Log.error("Search failed", reason);
    };

    Promise.all([axios.get<SearchItemResponseDTO>(api, { signal: controller.signal }), GLOB.menuService.hwTypesLoaded.promise, getAllSubsystems()]).then(
      onResolve,
      onReject
    ).finally(() => setLoading(false));


    return () => {
      controller.abort();
      changeTree([]);
    }
  }, [parm, api]);

  return [data, loading];
};

export type FilterFunc = (item: IHwTypeBase | Subsystem) => boolean;
export const getLabel = (key: keyof IHwTypeBase | keyof Subsystem, data: (IHwTypeBase | Subsystem)[]) => (type: string, filter?: FilterFunc) => {
  let dataCopy = [...data];
  if (filter)
    dataCopy = dataCopy.filter(filter);

  const indexedValues = dataCopy.map((ht: IHwTypeBase | Subsystem) => ht[key] as string);
  const indx = indexedValues.indexOf(type);

  if (indx === -1) {
    console.warn(`Add label for '${type}'`);
    return type;
  }

  return dataCopy[indx].label;
};

function treeTitleText(type: string) {
  if (type.startsWith('tag:'))
    return `Tag: ${type.replace('tag:', '')}`;

  if (type.startsWith('re:'))
    return `RegEx: ${type.replace('re:', '')}`;


  return `For: ${type}`;
}

const changeTree = (nodes: MenuNode[] = []) => {
  GLOB.setTree({ ...GLOB.getTree(), children: nodes });
  GLOB.refresh();
};

export default useSearch;