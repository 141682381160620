import { HostCfgDTO } from "@dto/hostCfgResponse.dto";
import { ITestSection } from "@dto/microserviceResponse.dto";
import { Modal } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FaCheck, FaExclamationTriangle, FaQuestion, FaTimes } from "react-icons/fa";
import { Spinner } from "src/component/spinner/Spinner";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";

export const ConnectionTestModal: FC<{ timestamp: number, cfg: HostCfgDTO }> = props => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState<ITestSection[]>([]);
  const [state] = useState({ sections: sections, error: false, canceled: false });
  const webSocket = useRef<WebSocket>();

  useEffect(() => {
    if (props.timestamp) {
      state.canceled = state.error = false;
      setLoading(true);
      setVisible(true);
      setSections(state.sections = []);
      webSocket.current?.close();
      const ws = webSocket.current = new WebSocket(GLOB.SERVER_WS_URL + "/websocket/mstest?hostcfg_id=" + props.cfg.hostcfg_id);
      ws.onerror = ev => {
        state.error = true;
        Log.error(`Connection test ${props.cfg.label} failed!`, ev);
        setLoading(false);
      }
      ws.onclose = ev => {
        if (state.error || state.canceled) return;
        setLoading(false);
        if (isSectionsPass())
          Log.success('Connection test passed for ' + props.cfg.label);
        else
          Log.warn('Connection test failed for ' + props.cfg.label);
      }
      ws.onmessage = ev => {
        const msg: ITestSection = JSON.parse(ev.data);
        if (Array.isArray(msg.text)) msg.text = msg.text.join('');
        setSections(prev => state.sections = [...prev, msg]);
      }
    }

    return () => {
      webSocket.current?.close();
    }
  }, [props.timestamp]);

  const closeDialog = useCallback(e => {
    if (loading) {
      state.canceled = true;
      webSocket.current?.close();
      setLoading(false);
      Log.info('Connection test canceled for ' + props.cfg.label);
    } else {
      setVisible(false);
    }
  }, [loading]);

  function isSectionsPass() {
    return state.sections.every(ts => ts.success || ts.acceptable);
  }

  return <Modal className="xm-connection-test-modal" style={{ top: '1em' }} width="60em" open={visible}
    title={loading ? `Trying connection to ${props.cfg?.label}...`
      : state.canceled ? <span>Connection test for {props.cfg?.label} canceled <FaQuestion style={{ marginLeft: '1em' }} color="gray" /></span>
        : !state.error && isSectionsPass() ?
          <span>Connected to {props.cfg?.label} <FaCheck className="xm-connection-status" color="darkgreen" /></span>
          : <span>Connection failed for {props.cfg?.label} <FaTimes className="xm-connection-status" color="darkred" /></span>}
    destroyOnClose={true} closable={false} maskClosable={false} cancelButtonProps={{ hidden: true }} okText={loading ? 'Cancel' : 'Close'}
    onOk={closeDialog}>
    Connection test results:
    <table className="xm-connection-table">
      <tbody>
        {sections.map(ts => <tr key={ts.label}>
          <td>
            <strong>{ts.label}</strong>
            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: ts.text }} />
          </td>
          <td>{ts.success ? <FaCheck className="xm-connection-status" color="darkgreen" />
            : ts.acceptable ? <FaExclamationTriangle className="xm-connection-status" color="darkorange" />
              : <FaTimes className="xm-connection-status" color="darkred" />}</td>
        </tr>)}
      </tbody>
    </table>
    {loading ? <Spinner /> : null}
  </Modal>
}