import { Tag, TagsResponseDTO } from "@dto/tag.dto";
import { TagValue, TagValuesResponseDTO } from "@dto/tagValue.dto";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from "react";
import { Log } from "src/service/Log";

interface ContextApiData {
  tags: Tag[];
  tagValues: TagValue[];
}

interface IAllTagsContext {
  fetchStarted: boolean,
  startFetch: () => void,
  isLoading: boolean,
  data: ContextApiData | undefined,
}

export const AllTagsContext = createContext<IAllTagsContext>(undefined);

function AllTags({ children }: PropsWithChildren) {
  const [fetchStarted, setFetchStarted] = useState(false);
  const startFetch = useCallback(() => setFetchStarted(true), []);
  const [data, setData] = useState<ContextApiData>();
  const [isLoading, setIsLoading] = useState(true);
  const init = useRef(false);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      return;
    }

    setIsLoading(true);
    const controller = new AbortController();
    Promise.all([
      axios.get<TagsResponseDTO>('/api/tag/v1', { signal: controller.signal }),
      axios.get<TagValuesResponseDTO>('/api/tag/v1/values', { signal: controller.signal }),
    ]).then(
      processData,
      (reason: AxiosError) => Log.error('Failed to get tags!', reason)
    ).finally(() => setIsLoading(false));

    function processData([
      { data: { data: tags } },
      { data: { data: tagValues } }
    ]: ApiData) {
      setData({ tags, tagValues });
    }

    return () => controller.abort();
  }, [fetchStarted]);

  return (
    <AllTagsContext.Provider value={{ fetchStarted, startFetch, isLoading, data }}>
      {children}
    </AllTagsContext.Provider>
  );
}

type ApiData = [
  AxiosResponse<TagsResponseDTO>,
  AxiosResponse<TagValuesResponseDTO>
];

export default AllTags;
